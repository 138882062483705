import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import styled from 'styled-components';
// components
import { Button, Icon, Title } from "../../atoms";
// consts
const POA_LINK = 'https://online.zenco.com/start/karehero/landing';
const CARE_EXPERT_CHAT_LINK = '/messenger';
/**
 * POADialogContent is the content for the Power of Attorney dialog.
 */
export const POADialogContent = () => {
    return (_jsxs(Wrapper, { children: [_jsx(SpacedIcon, { icon: 'book-heart', fontSize: 48 }), _jsx(DialogTitle, { children: "Your Power of Attorney" }), _jsx(DialogDescription, { children: "To get the best support from us, please send us your Power of Attorney documents." }), _jsx(Button, Object.assign({ ariaLabel: 'Submit Documents', to: CARE_EXPERT_CHAT_LINK }, { children: "Submit Documents" })), _jsx(Footer, { children: _jsxs(DialogDescription, { children: ["Don\u2019t have PoA yet?", ' ', _jsx(Button, Object.assign({ ariaLabel: 'get it here', variant: 'link', to: POA_LINK }, { children: "Get it here" }))] }) })] }));
};
const Wrapper = styled.div `
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
const DialogTitle = styled(Title) `
  margin: 0;
  color: ${(props) => props.theme.color.primary[80]};
  font: ${(props) => props.theme.typography.heading['4xl']};
  text-align: center;
`;
const DialogDescription = styled.div `
  font: ${(props) => props.theme.typography.body.md.regular};
  margin: ${(props) => props.theme.spacing[16]}
    ${(props) => props.theme.spacing[20]};
  color: ${(props) => props.theme.color.neutral[90]};
  text-align: center;
  display: flex;
  gap: ${(props) => props.theme.spacing[8]};
`;
const Footer = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
`;
const SpacedIcon = styled(Icon) `
  padding: ${(props) => props.theme.spacing[16]};
  background: ${(props) => props.theme.color.primary[80]};
  color: white;
  border-radius: 8px;
`;
POADialogContent.displayName = 'POADialogContent';
export default POADialogContent;
