import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import styled from 'styled-components';
import { useState } from 'react';
// components
import { TextArea, Button } from "../../atoms";
export const Note = ({ isReadOnly = false, content, placeholder = 'Write a note', }) => {
    const [value, setValue] = useState(content);
    return (_jsxs(StyledNoteWrapper, { children: [_jsx(StyledTopSection, { children: _jsx(TextArea, { isDisabled: isReadOnly, size: 'sm', placeholder: placeholder, value: value, onChange: (val) => setValue(val) }) }), !isReadOnly && (_jsx(Button, Object.assign({ ariaLabel: 'save', variant: 'secondary', isFullWidth: true }, { children: "Save" })))] }));
};
const StyledNoteWrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[16]};
  align-items: center;
`;
const StyledTopSection = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[8]};
`;
Note.displayName = 'Note';
export default Note;
