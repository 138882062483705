import { jsx as _jsx } from "react/jsx-runtime";
// react
import styled from 'styled-components';
// components
import { Note } from "../../molecules";
import { Accordion, Container } from "../../atoms";
// theme
import { theme } from "../../../theme";
export const NoteAccordion = ({ label, isReadOnly = false, content, }) => {
    return (_jsx(StyledContainer, { children: _jsx(Accordion, { titleSize: 'md', items: [
                {
                    id: 'note',
                    label: label,
                    icon: 'edit',
                    primaryColor: theme.color.neutral[90],
                    secondaryColor: theme.color.neutral[10],
                    content: _jsx(Note, { isReadOnly: isReadOnly, content: content }),
                },
            ] }) }));
};
const StyledContainer = styled(Container) `
  height: fit-content;
  border-top: 1px solid ${(props) => props.theme.color.neutral[60]};
  background: ${(props) => props.theme.color.neutral[10]};
  border-bottom-right-radius: ${(props) => props.theme.spacing[8]};
  border-bottom-left-radius: ${(props) => props.theme.spacing[8]};
  padding-bottom: ${(props) => props.theme.spacing[12]};
  box-shadow: ${({ theme }) => theme.elevation[2]};
`;
NoteAccordion.displayName = 'NoteAccordion';
export default NoteAccordion;
