import { jsx as _jsx } from "react/jsx-runtime";
// react
import styled, { css } from 'styled-components';
// theme
import theme from "../../../theme";
/**
 * Background renders a background.
 */
export const Background = ({ className, children, color = theme.color.neutral[10], gradient = 'none', src, }) => {
    return (_jsx(Wrapper, Object.assign({ className: className, "$color": color, "$src": src, "$gradient": gradient }, { children: children })));
};
const Wrapper = styled.div `
  height: 100%;
  background: ${(props) => {
    switch (props.$gradient) {
        case 'top':
            return css `linear-gradient(180deg, ${props.$color} -60%, transparent 110%)`;
        case 'bottom':
            return css `linear-gradient(0deg, ${props.$color} -60%, transparent 110%)`;
        case 'left':
            return css `linear-gradient(90deg, ${props.$color} -60%, transparent 110%)`;
        case 'right':
            return css `linear-gradient(270deg, ${props.$color} -60%, transparent 110%)`;
        case 'none':
            return props.$color;
        default:
            return props.$color;
    }
}};
  ${(props) => props.$src &&
    css `
      background-image: url(${props.$src});
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    `};
`;
Background.displayName = 'Background';
export default Background;
