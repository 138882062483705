import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import styled from 'styled-components';
import { Link } from 'react-router-dom';
// components
import { Button, Card, Chip, Divider, Icon } from "../../atoms";
import { ShortlistProgress } from "../../organisms";
// fixtures
import { shortlistItems } from "../../organisms/ShortlistProgress/fixtures";
export const CareHomeCard = ({ regulatoryID = '', headerImageUrl = '', name = '', address = '', rating = '', phone = '', notes = '', specialisms = [], careServiceType = [], available = false, isSecured = false, onMarkSecured, }) => {
    // helper functions
    const getIconForRating = (rating) => {
        const normalizedRating = rating.toLowerCase();
        switch (normalizedRating) {
            case 'good':
            case 'requires improvement':
            case 'inadequate':
                return { icon: 'circle', type: 'fas' };
            case 'registered - awaiting online inspection':
                return { icon: 'circle', type: 'fal' };
            case 'outstanding':
                return { icon: 'star', type: 'fas' };
            default:
                return { icon: 'circle', type: 'fas' };
        }
    };
    const formattedRating = `${rating.charAt(0).toUpperCase() + rating.slice(1)}`;
    const iconConfig = getIconForRating(rating);
    return (_jsxs(Content, Object.assign({ to: `/directory?provider=${regulatoryID}` }, { children: [_jsxs(ContentHeader, { children: [_jsx("img", { src: headerImageUrl, alt: 'header' }), available && (_jsx(ChipContainer, { children: _jsx(Chip, { label: 'Rooms available' }, 'rooms-available') }))] }), _jsxs(StyledCard, { children: [_jsxs(CardDescription, { children: [_jsx(CardTitle, { children: name }), _jsx(CardSubTitle, { children: address }), _jsx(ChipWrapper, { children: careServiceType
                                    .filter((x) => !!x.name)
                                    .map((service) => (_jsx(Chip, { label: service.name || '' }, service.id))) }), _jsxs(SubTitleWrapper, { children: [_jsx(StyledIcon, { icon: iconConfig.icon, fontSize: 16, type: iconConfig.type, rating: rating }), _jsxs(SubTitle, { children: [_jsx(BoldRating, { children: formattedRating }), " - Regulator's rating"] })] })] }), _jsx(ShortlistProgress, { shortlistItems: shortlistItems }), _jsxs(CardFooter, { children: [phone && (_jsxs(Button, Object.assign({ to: `tel:${phone}`, ariaLabel: 'phone', variant: 'secondary' }, { children: [_jsx(Icon, { icon: 'phone', fontSize: 16 }), _jsx(PhoneNumber, Object.assign({ href: `tel:${phone}` }, { children: phone }))] }))), _jsx(Button, Object.assign({ ariaLabel: isSecured ? 'secured' : 'i have secured this care service', onPress: onMarkSecured, isFullWidth: true }, { children: isSecured ? 'Secured' : 'I have secured this care service' })), _jsxs(StyledLabel, { children: [_jsx(Icon, { icon: 'info-circle', fontSize: 14 }), " Confirm so we can guide on you on managing care effectively"] }), _jsx(Divider, { isOr: false }), _jsxs(NoteWrapper, { children: [_jsxs(NoteHeading, { children: [_jsx(Icon, { icon: 'edit', size: 'lg', fontSize: 14 }), _jsx(NoteTitle, { children: "Care Expert Note" })] }), _jsx(Note, { children: notes || 'No note' })] })] })] })] })));
};
const StyledCard = styled(Card) `
  gap: 0;
  border: 0;
  height: 100%;
  background: none;
  min-width: 250px;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  padding: ${(props) => props.theme.spacing[32]};
  margin: ${(props) => props.theme.spacing[2]};
  box-shadow: none;
`;
const CardDescription = styled.div `
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[16]};
  border-bottom: 1px solid ${(props) => props.theme.color.neutral[40]};
  font: ${(props) => props.theme.typography.body.md.regular};
`;
const ContentHeader = styled.div `
  position: relative;
  border-radius: 8px 8px 0 0;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;
    user-drag: none;
    -webkit-user-drag: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    border-radius: 8px 8px 0 0;
    max-height: 200px;
  }
`;
const CardFooter = styled.div `
  display: flex;
  margin-top: auto;
  flex-direction: column;
  border-top: 1px solid ${(props) => props.theme.color.neutral[40]};
  padding-top: ${(props) => props.theme.spacing[24]};
  gap: ${(props) => props.theme.spacing[16]};
`;
const CardTitle = styled.div `
  color: ${(props) => props.theme.color.neutral[100]};
  font: ${(props) => props.theme.typography.body.xl.semibold};
`;
const Content = styled(Link) `
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  min-width: 300px;
  max-width: 400px;
  max-height: fit-content;
  border: 1px solid ${(props) => props.theme.color.neutral[40]};
  background: ${(props) => props.theme.color.neutral[10]};
  box-shadow: ${(props) => props.theme.elevation[1]};
  transition-duration: 0.2s;
  &:hover {
    transform: scale(1.03);
  }
`;
const CardSubTitle = styled.div `
  font: ${(props) => props.theme.typography.body.md.regular};
`;
const ChipWrapper = styled.div `
  display: flex;
  flex-wrap: wrap;
  gap: ${(props) => props.theme.spacing[8]};
`;
const SubTitleWrapper = styled.div `
  display: flex;
  align-items: center;
  padding-bottom: ${(props) => props.theme.spacing[24]};
`;
const SubTitle = styled.div `
  display: inline;
  margin-left: ${(props) => props.theme.spacing[8]};
`;
const BoldRating = styled.span `
  font: ${(props) => props.theme.typography.body.md.semibold};
`;
const StyledIcon = styled(Icon) `
  color: ${(props) => {
    switch (props.rating.toLowerCase()) {
        case 'inadequate':
            return props.theme.color.danger[50];
        case 'registered - awaiting online inspection':
            return props.theme.color.neutral[100];
        case 'requires improvement':
            return props.theme.color.warning[40];
        case 'outstanding':
        case 'good':
            return props.theme.color.success[40];
        default:
            return props.theme.color.neutral[40];
    }
}};
`;
const StyledLabel = styled.div `
  color: ${(props) => props.theme.color.neutral[90]};
  font: ${(props) => props.theme.typography.body.sm.regular};
`;
const ChipContainer = styled.div `
  position: absolute;
  top: ${(props) => props.theme.spacing[8]};
  left: ${(props) => props.theme.spacing[8]};
`;
const PhoneNumber = styled.a `
  display: inline;
  text-decoration: underline;
  color: ${(props) => props.theme.color.primary[80]};
  padding-left: ${(props) => props.theme.spacing[8]};
  font: ${(props) => props.theme.typography.body.md.semibold};
`;
const Note = styled.div `
  display: flex;
  min-height: 5rem;
  max-height: 10rem;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.color.neutral[40]};
  padding: 8px;
  overflow: scroll;
  font: ${(props) => props.theme.typography.body.sm.regular};
`;
const NoteWrapper = styled.div `
  display: flex;
  flex-direction: column;
`;
const NoteHeading = styled.div `
  display: flex;
  gap: ${(props) => props.theme.spacing[8]};
  padding-bottom: ${(props) => props.theme.spacing[8]};
`;
const NoteTitle = styled.div `
  font: ${(props) => props.theme.typography.body.md.semibold};
`;
CareHomeCard.displayName = 'CareHomeCard';
export default CareHomeCard;
