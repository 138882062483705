import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { css } from 'styled-components';
/**
 * DrawerTemplate is a template for a drawer with content.
 */
export const DrawerTemplate = ({ drawer, content, isNoPadding, isOverflowHidden, navBarMobile, }) => {
    return (_jsxs(Wrapper, { children: [_jsxs(StyledDrawerTemplate, { children: [_jsx(StyledDrawer, { children: drawer }), _jsx(StyledContent, Object.assign({ id: 'drawer-template-content', "$isOverflowHidden": isOverflowHidden, "$isNoPadding": isNoPadding }, { children: content }))] }), navBarMobile && _jsx(StyledNavBarMobile, { children: navBarMobile })] }));
};
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
`;
const StyledDrawerTemplate = styled.div `
  display: flex;
  flex-direction: row;
  overflow: hidden;
  flex: 1;
`;
const StyledDrawer = styled.div `
  display: none;
  ${(props) => props.theme.breakpoint.sm(css `
      display: block;
      flex: 0 0 auto;
    `)}
`;
const StyledContent = styled.div `
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0;
  ${(props) => !props.$isNoPadding &&
    css `
      padding: ${props.theme.spacing[24]} ${props.theme.spacing[12]};
    `}

  ${(props) => !props.$isNoPadding &&
    props.theme.breakpoint.sm(css `
      padding: ${props.theme.spacing[36]} ${props.theme.spacing[48]};
    `)}

  ${(props) => props.$isOverflowHidden &&
    css `
      overflow: hidden;
    `}
  transition: padding 0ms ease-in-out 125ms;
`;
const StyledNavBarMobile = styled.div `
  z-index: 1;
`;
DrawerTemplate.displayName = 'DrawerTemplate';
export default DrawerTemplate;
