import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// react
import { Link as ReactLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
// components
import { Icon } from "../../atoms";
import ProfileSummary from "../ProfileSummary/ProfileSummary";
/**
 * NavVertical is a navigation drawer that slides in from the left side of the
 * screen.
 */
export const NavVertical = ({ className, imgSrc, fallback, name, role, links, currentLink, isCollapsed, onAfterNavigate, isHideSummary = false, }) => {
    return (_jsx(_Fragment, { children: _jsxs(StyledNavVertical, Object.assign({ className: className, "$isCollapsed": isCollapsed }, { children: [!isHideSummary && (_jsx(Header, { children: _jsx(ProfileSummary, { imgSrc: imgSrc, fallback: fallback, name: name, role: role, isText: !isCollapsed, mode: 'light' }) })), _jsx(Links, { children: links === null || links === void 0 ? void 0 : links.map((link) => (_jsx("div", { children: link.to && (_jsxs(StyledLink, Object.assign({ to: link.to, onClick: () => onAfterNavigate === null || onAfterNavigate === void 0 ? void 0 : onAfterNavigate(), "$isCurrent": currentLink === link.to, "$isIconOnly": isCollapsed }, { children: [_jsx(Icon, { icon: link.icon, fontSize: 16, type: currentLink === link.to ? 'fas' : 'fal' }), !isCollapsed && link.label] }), link.label)) }, link.label))) })] })) }));
};
const StyledNavVertical = styled.div `
  position: relative;
  display: inline-flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[64]};
  height: 100%;
  box-sizing: border-box;
  ${(props) => !props.$isCollapsed &&
    css `
      width: 100%;
    `}
`;
const Header = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[16]};
`;
const Links = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[20]};
  padding: 0 ${(props) => props.theme.spacing[16]};
`;
const StyledLink = styled(ReactLink) `
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing[12]};
  font: ${(props) => props.theme.typography.body.md.medium};
  color: ${(props) => props.theme.color.neutral[70]};
  padding-right: ${(props) => props.theme.spacing[4]};
  min-height: 36px;
  cursor: pointer;
  box-sizing: border-box;
  text-decoration: none;
  width: min-content;
  white-space: nowrap;
  transition-duration: 0.2s;
  border-bottom: 2px solid transparent;

  &:hover {
    color: ${(props) => props.theme.color.primary[80]};
  }

  ${(props) => props.$isCurrent &&
    css `
      color: ${props.theme.color.primary[80]};
      border-bottom: 2px solid ${props.theme.color.primary[80]};
      pointer-events: none;
    `}

  ${(props) => props.$isIconOnly &&
    css `
      justify-content: center;
    `}
`;
NavVertical.displayName = 'NavVertical';
export default NavVertical;
