var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useRef } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
// react-aria
import { mergeProps, useButton, useFocusRing } from 'react-aria';
// icons
import { Icon } from "../Icon";
// assets
// @transform-path ../../../../../../src/assets/social/google-icon.svg
import googleIcon from "../../../../../../src/assets/social/google-icon.svg";
/**
 * ButtonSocial allows the user to trigger an action or event, such as submitting a
 * form, opening a dialog, canceling an action, or performing a delete
 * operation.
 */
export const ButtonSocial = (_a) => {
    var { className, isDisabled = false, isInline = false, isFullWidth = false, type = 'button', ariaLabel, children, to, variant = 'facebook' } = _a, props = __rest(_a, ["className", "isDisabled", "isInline", "isFullWidth", "type", "ariaLabel", "children", "to", "variant"]);
    // hooks
    const ref = useRef(null);
    const { isFocusVisible, focusProps } = useFocusRing();
    const { buttonProps, isPressed } = useButton(Object.assign(Object.assign({}, props), { isDisabled, type }), ref);
    return (_jsxs(StyledButtonSocial, Object.assign({}, mergeProps(buttonProps, focusProps), { to: to }, { className: className, ref: ref, "aria-label": ariaLabel, "$isFocusVisible": isFocusVisible, "$isPressed": isPressed, "$isInline": isInline, "$isFullWidth": isFullWidth, "$variant": variant, as: to ? Link : 'button' }, { children: [socialModel[variant].customIcon && (_jsx(StyledImg, { src: socialModel[variant].customIcon })), socialModel[variant].icon && (_jsx(StyledIcon, { icon: socialModel[variant].icon || 'star', type: socialModel[variant].iconType || 'fab', fontSize: 24 })), _jsx(StyledButtonSocialLabel, { children: children || socialModel[variant].defaultLabel })] })));
};
const StyledButtonSocial = styled.button `
  display: ${(props) => (props.$isInline ? 'inline-flex' : 'flex')};
  justify-content: center;
  align-items: center;
  gap: ${(props) => props.theme.spacing[12]};

  border: none;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  user-select: none;
  outline: none;

  font: ${(props) => props.theme.typography.button.md};
  text-decoration: none;

  ${(props) => {
    if (socialModel[props.$variant].isInvert) {
        return css `
        background: ${socialModel[props.$variant].color};
        color: ${(props) => props.theme.color.neutral[10]};
      `;
    }
    else {
        return css `
        background: ${(props) => props.theme.color.neutral[10]};
        border: 1px solid ${socialModel[props.$variant].color};
        color: ${socialModel[props.$variant].color};
      `;
    }
}}

  padding: ${(props) => props.theme.spacing[12]}
    ${(props) => props.theme.spacing[16]};

  border-radius: 99999px;

  ${(props) => props.$isFullWidth &&
    css `
      width: 100%;
    `}

  &:disabled {
    pointer-events: none;
  }
`;
const StyledButtonSocialLabel = styled.div `
  white-space: nowrap;
`;
const StyledIcon = styled(Icon) `
  position: absolute;
  top: 50%;
  left: ${(props) => props.theme.spacing[16]};
  transform: translateY(-50%);
  width: 24px;
  display: flex;
  align-items: center;
`;
const StyledImg = styled.img `
  position: absolute;
  top: 50%;
  left: ${(props) => props.theme.spacing[16]};
  transform: translateY(-50%);
`;
const socialModel = {
    apple: {
        icon: 'apple',
        color: '#000000',
        defaultLabel: 'Sign in with Apple',
        isInvert: true,
    },
    google: {
        customIcon: googleIcon,
        color: '#000000',
        defaultLabel: 'Sign in with Google',
    },
    facebook: {
        icon: 'facebook',
        color: '#1877f2',
        defaultLabel: 'Sign in with Facebook',
    },
    email: {
        icon: 'envelope',
        iconType: 'fal',
        color: '#000000',
        defaultLabel: 'Sign in with Email',
    },
};
ButtonSocial.componentName = 'ButtonSocial';
export default ButtonSocial;
