import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useMemo } from 'react';
import styled from 'styled-components';
// assets
// @transform-path ../../../../../../src/assets/logo/logo-text-dark.svg
import logoTextDark from "../../../../../../src/assets/logo/logo-text-dark.svg";
// @transform-path ../../../../../../src/assets/logo/logo-text-light.svg
import logoTextLight from "../../../../../../src/assets/logo/logo-text-light.svg";
// @transform-path ../../../../../../src/assets/logo/logo-text-white.svg
import logoTextWhite from "../../../../../../src/assets/logo/logo-text-white.svg";
// @transform-path ../../../../../../src/assets/logo/logo-symbol-dark.svg
import logoSymbolDark from "../../../../../../src/assets/logo/logo-symbol-dark.svg";
// @transform-path ../../../../../../src/assets/logo/logo-symbol-light.svg
import logoSymbolLight from "../../../../../../src/assets/logo/logo-symbol-light.svg";
// @transform-path ../../../../../../src/assets/logo/logo-symbol-light.svg
import logoSymbolWhite from "../../../../../../src/assets/logo/logo-symbol-light.svg";
/**
 * Logo displays the KareHero logo.
 */
export const Logo = ({ size = 'md', variant = 'text', color = 'dark', }) => {
    // memos
    const symbolSrc = useMemo(() => {
        switch (color) {
            case 'light':
                return logoSymbolLight;
            case 'white':
                return logoSymbolWhite;
            default:
                return logoSymbolDark;
        }
    }, [color]);
    const textSrc = useMemo(() => {
        switch (color) {
            case 'light':
                return logoTextLight;
            case 'white':
                return logoTextWhite;
            default:
                return logoTextDark;
        }
    }, [color]);
    return (_jsxs(StyledLogo, Object.assign({ size: size }, { children: [(variant === 'symbol' || variant === 'full') && (_jsx("img", { src: symbolSrc, alt: 'logo symbol' })), (variant === 'text' || variant === 'full') && (_jsx("img", { src: textSrc, alt: 'logo text' }))] })));
};
const StyledLogo = styled.div `
  display: flex;
  gap: ${(props) => props.theme.spacing[8]};
  & > img {
    object-fit: contain;
    ${(props) => {
    switch (props.size) {
        case 'sm':
            return `
            min-height: 16px;
            max-height: 16px;
          `;
        case 'md':
            return `
            min-height: 23px;
            max-height: 23px;
          `;
        case 'lg':
            return `
            min-height: 36px;
            max-height: 36px;
          `;
        case 'xl':
            return `
            min-height: 48px;
            max-height: 48px;
          `;
        case 'fill':
            return `
            width: 100%;
          `;
    }
}};
  }
`;
Logo.displayName = 'Logo';
export default Logo;
