import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// react
import styled from 'styled-components';
// components
import { Background, Container, Loader, Title } from "../../atoms";
import { MemphisHeader } from "../../molecules";
import { HorizontalSplit } from "../../templates";
import { EditorFields } from "../../organisms/EditorFields/EditorFields";
import { createFieldHelper, FieldType, } from "../../organisms/EditorFields/fieldHelper";
// theme
import theme from "../../../theme";
const fields = createFieldHelper({
    code: {
        field: {
            type: FieldType.InviteCode,
        },
    },
});
/**
 * RegisterInviteCode is the page where the user can register with an invite code.
 */
export const RegisterInviteCode = ({ value, onChange, isLoading, }) => {
    return (_jsx(HorizontalSplit, { topContent: _jsx(MemphisHeader, {}), bottomContent: _jsx(Background, Object.assign({ color: theme.color.cards.yellow.tertiary }, { children: _jsx(Container, Object.assign({ size: 'sm' }, { children: _jsx(Wrapper, { children: isLoading ? (_jsx(Loader, {})) : (_jsxs(_Fragment, { children: [_jsx(Title, Object.assign({ size: 'sm', isNoMargin: true }, { children: "Enter your invite code" })), _jsx(Description, { children: "Head over to your email inbox to find the invite code." }), _jsx(EditorFields, { fields: fields, value: { code: value }, onChange: (val) => onChange === null || onChange === void 0 ? void 0 : onChange(val.code), isFullWidth: false })] })) }) })) })) }));
};
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${(props) => props.theme.spacing[16]};
  padding: ${(props) => props.theme.spacing[32]} 0;
`;
const Description = styled.div `
  font: ${(props) => props.theme.typography.body.lg.regular};
  color: ${(props) => props.theme.color.neutral[90]};
  text-align: center;
`;
RegisterInviteCode.displayName = 'RegisterInviteCode';
export default RegisterInviteCode;
