import { jsx as _jsx } from "react/jsx-runtime";
// react
import styled, { css } from 'styled-components';
// @transform-path ../../../../../../src/assets/corporate-memphis/happy-family1.png
import happyFamily1 from "../../../../../../src/assets/corporate-memphis/happy-family1.png";
// @transform-path ../../../../../../src/assets/corporate-memphis/elderly-couple1.png
import elderlyCouple1 from "../../../../../../src/assets/corporate-memphis/elderly-couple1.png";
// @transform-path ../../../../../../src/assets/corporate-memphis/elderly-couple2.png
import elderlyCouple2 from "../../../../../../src/assets/corporate-memphis/elderly-couple2.png";
// @transform-path ../../../../../../src/assets/corporate-memphis/kindness1.png
import kindness1 from "../../../../../../src/assets/corporate-memphis/kindness1.png";
const images = {
    'happy-family-1': happyFamily1,
    'elderly-couple-1': elderlyCouple1,
    'elderly-couple-2': elderlyCouple2,
    'kindness-1': kindness1,
};
export const Memphis = ({ className, variant = 'happy-family-1', size = 'md', }) => {
    return (_jsx(Wrapper, Object.assign({ className: className, "$size": size }, { children: _jsx(Image, { src: images[variant], alt: 'warm image' }) })));
};
const Wrapper = styled.div `
  display: flex;
  justify-content: center;
  align-items: flex-end;
  overflow: hidden;

  ${(props) => {
    switch (props.$size) {
        case 'sm':
            return css `
          height: 100px;
        `;
        case 'md':
            return css `
          height: 250px;
        `;
        case 'lg':
            return css `
          height: 400px;
        `;
    }
}}
`;
const Image = styled.img `
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: contain;
  max-height: 100%;
`;
Memphis.displayName = 'Memphis';
export default Memphis;
