// react
import { useCallback } from 'react';

// store
import { kindeClientId, kindeEnvironmentUrl } from 'store/api';
import { useSignOutMutation } from 'store/api/iam';

const env = process.env.REACT_APP_BUILD_ENVIRONMENT;
const ConnectionID = {
  GOOGLE: 'conn_018f08586998f0eac9cfe1e83083a85d',
  FACEBOOK: 'conn_018f085869984eededa5e9c89ea51977',
  APPLE: 'conn_018f0858699813cd2bcae3e27246eddf',
  EMAIL:
    env === 'production'
      ? 'conn_018f085869975f5b856eda3f9e4e3df6'
      : 'conn_018f085869531133e802e4ce49939ea9',
};

interface KindeURLOptions {
  connectionID?: string;
  redirectURI?: string;
  isRegister?: boolean;
  email?: string;
}

export const useKinde = () => {
  // hooks
  const [signOutMutation] = useSignOutMutation();

  // methods
  const generateKindeURL = useCallback(
    ({
      connectionID,
      redirectURI = `${window.location.origin}/authenticate`,
      isRegister = false,
      email,
    }: KindeURLOptions) => {
      const url = new URL(`https://${kindeEnvironmentUrl}/oauth2/auth`);

      const queryParams = new URLSearchParams({
        response_type: 'code',
        client_id: kindeClientId,
        redirect_uri: redirectURI,
        scope: 'openid profile email',
        state: 'i3Dd8bE1lX', // random state
      });
      if (connectionID) queryParams.set('connection_id', connectionID);
      if (isRegister) queryParams.set('prompt', 'create');
      if (email) queryParams.set('login_hint', email);

      url.search = queryParams.toString();
      return url.toString();
    },
    [],
  );

  const signIn = useCallback(
    (connectionID?: string, email?: string) => {
      window.location.href = generateKindeURL({ connectionID, email });
    },
    [generateKindeURL],
  );

  const register = useCallback(
    (connectionID?: string, email?: string) => {
      window.location.href = generateKindeURL({
        connectionID,
        redirectURI: `${window.location.origin}/register/member`,
        isRegister: true,
        email,
      });
    },
    [generateKindeURL],
  );

  const signOut = useCallback(() => {
    signOutMutation();

    let kindeLogoutUrl = new URL(`https://${kindeEnvironmentUrl}/logout`);
    const queryParams = new URLSearchParams({
      redirect: `${window.location.origin}/sign-in`,
    });
    kindeLogoutUrl.search = queryParams.toString();

    window.location.href = kindeLogoutUrl.toString();
  }, [signOutMutation]);

  return {
    signIn,
    signOut,
    register,
    generateKindeURL,
    ConnectionID,
  };
};
