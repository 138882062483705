import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
// components
import { Icon } from "../../atoms/Icon";
// theme
import { theme } from "../../../theme";
/**
 * ActionPlanPathway displays a pathway of action plan categories.
 */
export const ActionPlanPathway = ({ className, actionPlanCategories, }) => {
    return (_jsx(Wrapper, Object.assign({ className: className }, { children: actionPlanCategories === null || actionPlanCategories === void 0 ? void 0 : actionPlanCategories.map((category, i) => (_jsxs(CategoryWrapper, Object.assign({ "$isFlip": i % 2 == 1 }, { children: [_jsxs(IconButton, Object.assign({ to: `/action-plan/category/${category.id}` }, { children: [_jsx(Icon, { icon: category.icon, fill: theme.color.actionPlanCards[category.color].secondary, size: '3x', isElevation: true }), _jsxs(Info, { children: [_jsx(Title, { children: category.title }), category.completedItems !== undefined &&
                                    category.totalItems !== undefined && (_jsxs(Progress, { children: [category.completedItems, "/", category.totalItems] }))] })] })), i !== actionPlanCategories.length - 1 ? (_jsx(Connector, { "$isFlip": i % 2 == 1 })) : (_jsx("div", {}))] })))) })));
};
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${(props) => props.theme.spacing[32]};
  padding-top: 0;
  padding-bottom: ${(props) => props.theme.spacing[64]};
`;
const CategoryWrapper = styled.div `
  display: flex;
  align-items: flex-start;
  height: min-content;
  flex-direction: ${(props) => (props.$isFlip ? 'row-reverse' : 'row')};
  padding-${(props) => (props.$isFlip ? 'left' : 'right')}: 50px;
`;
const IconButton = styled(Link) `
  all: unset;
  position: relative;
  transition-duration: 0.2s;
  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
`;
const Connector = styled.div `
  background: transparent;
  border-top: 2px dashed ${theme.color.neutral[70]};
  ${(props) => props.$isFlip
    ? css `
          border-left: 2px dashed ${theme.color.neutral[70]};
          border-top-left-radius: 32px;
        `
    : css `
          border-right: 2px dashed ${theme.color.neutral[70]};
          border-top-right-radius: 32px;
        `}
  min-height: calc(50px + 25px);
  margin-top: 50px;
  width: 100%;
`;
const Info = styled.div `
  position: absolute;
  left: 50%;
  bottom: -${(props) => props.theme.spacing[4]};
  transform: translate(-50%, 100%);
  display: flex;
  flex-direction: column;
  white-space: nowrap;
`;
const Title = styled.div `
  text-align: center;
  font: ${(props) => props.theme.typography.body.lg.medium};
  color: ${(props) => props.theme.color.neutral[100]};
`;
const Progress = styled.div `
  text-align: center;
  font: ${(props) => props.theme.typography.body.md.regular};
  color: ${(props) => props.theme.color.neutral[90]};
`;
ActionPlanPathway.displayName = 'ActionPlanPathway';
export default ActionPlanPathway;
