// store
import { useGetAllArticleFilteredQuery } from 'store/api/cms';
import { getAccount } from 'store/slices/iamSlice';
import { useSelector } from 'store';

// components
import { ArticleList } from '@karehero/llama';

/**
 * SJPResources shows the content library.
 */
const SJPResources = () => {
  // hooks
  const { data: articles, isLoading } = useGetAllArticleFilteredQuery();
  const account = useSelector(getAccount);

  return (
    <ArticleList
      articles={articles}
      isLoading={isLoading}
      organizationName={account?.organization?.name}
    />
  );
};

export default SJPResources;
