import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import styled from 'styled-components';
import { css } from 'styled-components';
import { Link } from 'react-router-dom';
// components
import { Icon } from "../Icon";
/**
 * NavBarMobile shows the mobile navigation bar.
 */
export const NavBarMobile = ({ className, links, currentPath = '/', }) => {
    return (_jsx(Wrapper, Object.assign({ className: className }, { children: links === null || links === void 0 ? void 0 : links.map((linkItem) => linkItem.to ? (_jsxs(StyledLink, Object.assign({ to: linkItem.to, "$isSelected": currentPath === linkItem.to }, { children: [_jsx(StyledIcon, { icon: linkItem.icon, fontSize: 24, type: currentPath === linkItem.to ? 'fas' : 'fal', "$isSelected": currentPath === linkItem.to }), _jsx(Label, { children: linkItem.label })] }), linkItem.to)) : (_jsxs(StyledLinkAction, Object.assign({ onClick: linkItem.onClick, "$isSelected": currentPath === linkItem.to }, { children: [_jsx(StyledIcon, { icon: linkItem.icon, fontSize: 24, type: currentPath === linkItem.to ? 'fas' : 'fal', "$isSelected": currentPath === linkItem.to }), _jsx(Label, { children: linkItem.label })] }), linkItem.label))) })));
};
const Wrapper = styled.div `
  display: flex;
  flex-direction: row;
  align-items: stretch;
  background: ${(props) => props.theme.color.neutral[10]};
  box-shadow: ${(props) => props.theme.elevation.navBar};
  overflow: visible;
  height: 100%;
`;
const StyledIcon = styled(Icon) `
  color: ${(props) => props.theme.color.neutral[70]};
  ${(props) => props.$isSelected &&
    css `
      color: ${props.theme.color.primary[80]};
    `}
`;
const StyledLink = styled(Link) `
  all: unset;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${(props) => props.theme.spacing[4]};
  color: ${(props) => props.theme.color.neutral[70]};
  padding: ${(props) => props.theme.spacing[12]};
  user-select: none;
  cursor: pointer;
  flex: 1;
  border-top: 4px solid transparent;

  transition-duration: 0.2s;
  ${(props) => props.$isSelected &&
    css `
      border-top: 4px solid ${props.theme.color.primary[80]};
      color: ${props.theme.color.primary[80]};
      pointer-events: none;
    `}
`;
const StyledLinkAction = styled.div `
  all: unset;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${(props) => props.theme.spacing[4]};
  color: ${(props) => props.theme.color.neutral[70]};
  padding: ${(props) => props.theme.spacing[12]};
  user-select: none;
  cursor: pointer;
  flex: 1;
  border-top: 4px solid transparent;

  transition-duration: 0.2s;
  ${(props) => props.$isSelected &&
    css `
      border-top: 4px solid ${props.theme.color.primary[80]};
      color: ${props.theme.color.primary[80]};
      pointer-events: none;
    `}
`;
const Label = styled.div `
  font: ${(props) => props.theme.typography.body.xxs.semibold};
  text-align: center;
  user-select: none;
`;
NavBarMobile.displayName = 'NavBarMobile';
export default NavBarMobile;
