import { jsx as _jsx } from "react/jsx-runtime";
// react
import { useCallback, useState } from 'react';
// components
import { Dialog } from "../../atoms";
import { POADialogContent } from "../../molecules";
/**
 * POADialog is a popup dialog.
 */
export const POADialog = () => {
    // states
    const [isOpen, setIsOpen] = useState(true);
    // callbacks
    const handleClose = useCallback(() => {
        setIsOpen(false);
        sessionStorage.setItem('isPoaDialogShown', 'true');
    }, [setIsOpen]);
    return (_jsx(Dialog, Object.assign({ isOpen: isOpen && sessionStorage.getItem('isPoaDialogShown') !== 'true', setIsOpen: handleClose, variant: 'sheet', direction: 'bottom' }, { children: _jsx(POADialogContent, {}) })));
};
POADialog.displayName = 'POADialog';
export default POADialog;
