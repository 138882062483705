// react
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

// store
import {
  useCreateMemberMutation,
  useKindeAuthenticateMutation,
  useMigrateAuthenticateMutation,
} from 'store/api/iam';
import { getIsAuthenticated } from 'store/slices/iamSlice';
import { useSelector } from 'store';
import { apiUrl } from 'store/api';

// hooks
import { useApp, useCareCircle, useOnboarding } from 'hooks';

// components
import {
  AppStore,
  Dialog,
  Loading as LoadingPage,
  OnboardingFlow,
} from '@karehero/llama';

// launch darkly
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { createLdContext } from 'launchDarkly';

// models
import { MemberRole } from '@karehero/models';

/**
 * OnboardingMemberCreate takes the user through the onboarding flow for a care concierge.
 */
const OnboardingMemberCreate = () => {
  // hooks
  const flags = useFlags();
  const isAuthenticated = useSelector(getIsAuthenticated);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [createMember] = useCreateMemberMutation();
  const ldClient = useLDClient();
  const [kindeAuthenticate] = useKindeAuthenticateMutation();
  const [migrateAuthenticate] = useMigrateAuthenticateMutation();
  const { isApp } = useApp();
  const { currentCareCircleMember } = useCareCircle();
  const {
    introConfig,
    loadingConfig,
    feelingConfig,
    updateIsOnboardingQuestionsCompleted,
    updateIsOnboarded,
    personalization,
  } = useOnboarding();

  // refs
  const isRequested = useRef(false);

  // state
  const [isAppStoreOpen, setIsAppStoreOpen] = useState(!isApp);

  // methods
  // set search params
  const handleNext = useCallback(
    (configID: string, subConfigID: string) => {
      setSearchParams({ configID, subConfigID });
    },
    [setSearchParams],
  );

  // launch darkly
  const handleAnalyticsIdentify = useCallback(
    (res: any) => {
      const newContext = createLdContext(res.data);
      ldClient?.identify(newContext);
    },
    [ldClient],
  );

  // create account
  const handleCreate = useCallback(async (): Promise<boolean> => {
    const res = await createMember();
    if (!('data' in res)) {
      console.error('Failed to create member', res.error);
      return false;
    }

    handleAnalyticsIdentify(res);

    return true;
  }, [createMember, handleAnalyticsIdentify]);

  // complete
  const handleComplete = useCallback(async () => {
    await updateIsOnboardingQuestionsCompleted();
    await updateIsOnboarded();
    if (personalization.find((p: string) => p === 'find-or-manage-care')) {
      navigate('/action-plan/karehero-finds-your-care?isHideBack=true');
    } else if (
      personalization.find((p: string) => p === 'help-with-funding-care')
    ) {
      navigate('/action-plan/karehero-helps-cover-costs?isHideBack=true');
    } else {
      navigate('/action-plan/book-call-with-care-expert?isHideBack=true');
    }
    window.location.reload();
  }, [
    updateIsOnboarded,
    navigate,
    updateIsOnboardingQuestionsCompleted,
    personalization,
  ]);

  // effects
  useEffect(() => {
    if (searchParams.get('authed')) {
      isRequested.current = true;
      return;
    }

    const code = searchParams.get('code');
    if (!code) {
      if (!isAuthenticated) navigate('/sign-in');
      return;
    }
    if (isRequested.current) return;

    const migration = !!searchParams.get('migration');

    (async () => {
      isRequested.current = true;
      const res = await kindeAuthenticate({ code, isMigration: migration });
      if ('error' in res) {
        console.error('Failed to register Kinde', res.error);
        window.location.href = '/';
        return;
      }

      if (migration) {
        const res2 = await migrateAuthenticate();
        if ('error' in res2) {
          console.error('Failed to authenticate migration', res2.error);
          window.location.href = '/';
          return;
        }
      } else {
        const res2 = await handleCreate();
        if (!res2) {
          console.error('Failed to create member');
          window.location.href = '/';
          return;
        }
      }

      setSearchParams({ authed: 'true', migration: `${migration}` });
    })();
  }, [
    searchParams,
    setSearchParams,
    navigate,
    kindeAuthenticate,
    migrateAuthenticate,
    isRequested,
    handleCreate,
    isAuthenticated,
  ]);

  // memos
  const defaultConfig = useMemo(
    () => [...introConfig, ...loadingConfig],
    [introConfig, loadingConfig],
  );

  const primaryCaregiverConfig = useMemo(
    () => [...introConfig, ...feelingConfig, ...loadingConfig],
    [introConfig, feelingConfig, loadingConfig],
  );

  const onboardingConfig = useMemo(() => {
    switch (currentCareCircleMember?.roles[0]?.name) {
      case MemberRole.PrimaryCaregiver:
        return primaryCaregiverConfig;
      default:
        return defaultConfig;
    }
  }, [currentCareCircleMember, defaultConfig, primaryCaregiverConfig]);

  return isAuthenticated ? (
    <>
      <OnboardingFlow
        config={onboardingConfig}
        onNext={handleNext}
        onComplete={handleComplete}
      />
      {flags.appStoreSignpost && (
        <Dialog
          variant='full'
          isOpen={isAppStoreOpen}
          setIsOpen={setIsAppStoreOpen}
        >
          <AppStore storeUrl={`${apiUrl}/v1/app-store`} />
        </Dialog>
      )}
    </>
  ) : (
    <LoadingPage />
  );
};

OnboardingMemberCreate.displayName = 'OnboardingMemberCreate';
export default OnboardingMemberCreate;
