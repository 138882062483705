import api from '.';
import { Account } from '@karehero/models';

export const accountApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllAccount: builder.query<Account[], void>({
      query: () => '/account',
      providesTags: ['Accounts'],
    }),
    getAccount: builder.query<Account, string>({
      query: (id) => `/account/${id}`,
      providesTags: ['Accounts'],
    }),
    createAccount: builder.mutation<Account, Account>({
      query: (account) => ({
        url: '/account',
        method: 'POST',
        body: account,
      }),
      invalidatesTags: ['Accounts'],
    }),
    migrateAccount: builder.mutation<Account, { email: string }>({
      query: (req) => ({
        url: '/account/migrate',
        method: 'POST',
        body: req,
      }),
      invalidatesTags: ['Accounts'],
    }),
    updateAccount: builder.mutation<Account, Account>({
      query: (account) => ({
        url: `/account`,
        method: 'PUT',
        body: account,
      }),
      invalidatesTags: ['Accounts'],
    }),
    deleteAccount: builder.mutation<Account, { id: string }>({
      query: ({ id }) => ({
        url: `/account/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Accounts', 'CareCircles', 'OrganizationMembers'],
    }),
    updateAccountHubSpotProperties: builder.mutation<Account, { id: string }>({
      query: ({ id }) => ({
        url: `/crm/update-contact/${id}`,
        method: 'PUT',
      }),
      invalidatesTags: ['Accounts'],
    }),
  }),
});

export const {
  useGetAllAccountQuery,
  useGetAccountQuery,
  useLazyGetAccountQuery,
  useCreateAccountMutation,
  useMigrateAccountMutation,
  useUpdateAccountMutation,
  useDeleteAccountMutation,
  useUpdateAccountHubSpotPropertiesMutation,
} = accountApi;

export default accountApi;
