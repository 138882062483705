import { jsx as _jsx } from "react/jsx-runtime";
// react
import { forwardRef } from 'react';
import styled from 'styled-components';
// react-transition-group
import { CSSTransition, SwitchTransition } from 'react-transition-group';
/**
 * Transition animates the entrance and exit of a component.
 */
export const Transition = forwardRef(({ children, path, speed = 125 }, ref) => {
    return (_jsx(SwitchTransition, { children: _jsx(CSSTransition, Object.assign({ nodeRef: ref, timeout: speed, classNames: 'fade', unmountOnExit: true }, { children: _jsx(AnimatedOutlet, Object.assign({ ref: ref, speed: speed }, { children: children })) }), path) }));
});
const AnimatedOutlet = styled.div `
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: visible;
  height: 100%;
  &.fade-enter {
    opacity: 0;
  }
  &.fade-enter-active {
    opacity: 1;
    transition-duration: ${(props) => props.speed}ms;
  }
  &.fade-exit {
    opacity: 1;
  }
  &.fade-exit-active {
    opacity: 0;
    transition-duration: ${(props) => props.speed}ms;
  }
`;
Transition.displayName = 'Transition';
export default Transition;
