/**
 * createFieldHelper asserts that the field definitions match the type of the
 * form data.
 */
export const createFieldHelper = (fields) => {
    return fields;
};
/**
 * FieldType is the component type of a field.
 */
export var FieldType;
(function (FieldType) {
    FieldType["Text"] = "text";
    FieldType["TextArea"] = "text-area";
    FieldType["Password"] = "password";
    FieldType["Email"] = "email";
    FieldType["Number"] = "number";
    FieldType["Select"] = "select";
    FieldType["SelectCustom"] = "select-custom";
    FieldType["MultiSelect"] = "multi-select";
    FieldType["MultiSelectChip"] = "multi-select-chip";
    FieldType["MultiSelectBox"] = "multi-select-box";
    FieldType["MultiSelectCustom"] = "multi-select-custom";
    FieldType["RadioGroup"] = "radio";
    FieldType["Boolean"] = "boolean";
    FieldType["DatePicker"] = "date";
    FieldType["DateRange"] = "date-range";
    FieldType["Contact"] = "contact";
    FieldType["Address"] = "address";
    FieldType["Time"] = "time";
    FieldType["Slider"] = "slider";
    FieldType["SliderSelect"] = "slider-select";
    FieldType["SliderSeverity"] = "slider-severity";
    FieldType["BooleanTable"] = "boolean-table";
    FieldType["DateOfBirth"] = "date-of-birth";
    FieldType["QuestionTable"] = "question-table";
    FieldType["CareCircleMemberSelector"] = "care-circle-member-selector";
    FieldType["TimePicker"] = "time-picker";
    FieldType["ToggleSelect"] = "toggle-select";
    FieldType["Switch"] = "switch";
    FieldType["InviteCode"] = "invite-code";
})(FieldType || (FieldType = {}));
