// react
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

// store
import { setCurrentCareCircleId } from 'store/slices/careCircleSlice';

// components
import { SignIn as SignInPage } from '@karehero/llama';

// hooks
import { useKinde } from 'hooks';

// config
import disallowedDomains from 'config/disallowedDomains';

/**
 * SignIn is the sign in page, and handles user authentication.
 */
const SignIn = () => {
  // hooks
  const dispatch = useDispatch();
  const { signIn, ConnectionID } = useKinde();
  const [searchParams] = useSearchParams();

  // memos
  const isProduction = useMemo(
    () =>
      process.env.REACT_APP_BUILD_ENVIRONMENT === 'production'
        ? true
        : undefined,
    [],
  );

  // effects
  useEffect(() => {
    dispatch(setCurrentCareCircleId(undefined));
  }, [dispatch]);

  return (
    <SignInPage
      onGoogleSignIn={isProduction && (() => signIn(ConnectionID.GOOGLE))}
      onFacebookSignIn={isProduction && (() => signIn(ConnectionID.FACEBOOK))}
      onAppleSignIn={isProduction && (() => signIn(ConnectionID.APPLE))}
      onEmailSignIn={(email: string) => signIn(ConnectionID.EMAIL, email)}
      message={searchParams.get('message') || undefined}
      disallowedDomains={disallowedDomains}
    />
  );
};

export default SignIn;
