import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useState } from 'react';
import styled from 'styled-components';
// styles
import { css } from 'styled-components';
// components
import { toGenericOnboarding, } from "../../pages";
import MultiSelectBox from "../../atoms/MultiSelectBox/MultiSelectBox";
import { Container, ProgressBar } from "../../atoms";
export const OnboardingMultiSelectBox = toGenericOnboarding(({ currentIndex = 0, config, }) => {
    var _a, _b, _c;
    // state
    const [value, setValue] = useState();
    return (_jsx(StyledContainer, Object.assign({ size: 'md' }, { children: _jsxs(Wrapper, { children: [_jsx(ProgressBar, { label: 'Progress', limit: config.length, progress: currentIndex + 1 }), _jsx(Body, { children: config && (_jsxs(_Fragment, { children: [_jsx(Title, { children: (_a = config[currentIndex]) === null || _a === void 0 ? void 0 : _a.title }), _jsx(Text, { children: (_b = config[currentIndex]) === null || _b === void 0 ? void 0 : _b.subTitle }), _jsx(MultiSelectBox, { options: [...(_c = config[currentIndex]) === null || _c === void 0 ? void 0 : _c.options], maxColumns: 1, size: 'sm', value: value, onChange: setValue, isCustom: true })] })) })] }) })));
});
const StyledContainer = styled(Container) `
  height: 100%;
  overflow-y: auto;
  padding: 0;
`;
const Wrapper = styled.div `
  display: flex;
  max-height: 100%;
  min-height: content;
  flex-direction: column;
  justify-content: center;
  padding: ${(props) => props.theme.spacing[32]}
    ${(props) => props.theme.spacing[24]};
`;
const Body = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[16]};
  padding-top: ${(props) => props.theme.spacing[32]};
`;
const Title = styled.div `
  width: 100%;
  color: ${(props) => props.theme.color.primary[80]};
  font: ${(props) => props.theme.typography.heading['3xl']};

  ${(props) => props.theme.breakpoint.md(css `
      font: ${props.theme.typography.heading['6xl']};
    `)}
`;
const Text = styled.div `
  color: ${(props) => props.theme.color.neutral[90]};
  font: ${(props) => props.theme.typography.body.xs.regular};

  ${(props) => props.theme.breakpoint.md(css `
      font: ${props.theme.typography.body.xl.regular};
    `)}
`;
OnboardingMultiSelectBox.displayName = 'OnboardingMultiSelectBox';
export default toGenericOnboarding(OnboardingMultiSelectBox);
