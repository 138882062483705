// store
import { useEffect, useState } from 'react';

// components
import { RegisterInviteCode as RegisterInviteCodePage } from '@karehero/llama';

// store
import { useRegisterMemberByInviteKeyMutation } from 'store/api/iam';

/**
 * RegisterInviteCode is the dashboard page.
 */
const RegisterInviteCode = () => {
  // state
  const [code, setCode] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  // hooks
  const [registerByInviteKey] = useRegisterMemberByInviteKeyMutation();

  // effects
  useEffect(() => {
    if (code.length >= 6) {
      setIsLoading(true);
      (async () => {
        try {
          const res = await registerByInviteKey({ inviteKey: code });
          if ('data' in res) {
            window.location.href = res.data.message;
          } else {
            throw new Error('Failed to register by invite key');
          }
        } catch (e) {
          console.error(e);
          setIsLoading(false);
          setCode('');
        }
      })();
    }
  }, [code, registerByInviteKey]);

  return (
    <RegisterInviteCodePage
      value={code}
      onChange={setCode}
      isLoading={isLoading}
    />
  );
};

export default RegisterInviteCode;
