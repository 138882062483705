// reacrt
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

// components
import { ActionPlanCategory as ActionPlanCategoryPage } from '@karehero/llama';

// hooks
import { useActionPlan } from 'hooks/useActionPlan';

/**
 * ActionPlanAll shows all action plan items.
 */
const ActionPlanCategory = () => {
  // hooks
  const { actionPlanCategories } = useActionPlan();
  const { actionPlanCategoryId } = useParams();

  // memos
  const actionPlanCategory = useMemo(
    () =>
      actionPlanCategories?.find(
        (category) => category.id === actionPlanCategoryId,
      ),
    [actionPlanCategories, actionPlanCategoryId],
  );

  return <ActionPlanCategoryPage actionPlanCategory={actionPlanCategory} />;
};

export default ActionPlanCategory;
