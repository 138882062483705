import { jsx as _jsx } from "react/jsx-runtime";
// react
import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
// components
//
import { BaseField } from "../../atoms";
import { Input } from "../../atoms";
/**
 * InviteCode shows an individual input field for each character of the invite
 * code.
 */
export const InviteCode = ({ className, length = 6, regex = /^[a-zA-Z0-9 ]$/, id, ariaLabel, label, description, tooltip, isRequired, isHideTitle, isDisabled, onChange, autoFocus = true, }) => {
    // state
    const [code, setCode] = useState(Array.from({ length }, () => ''));
    // memos
    const computedCode = useMemo(() => code.join(''), [code]);
    // effects
    useEffect(() => {
        if (onChange)
            onChange(computedCode);
    }, [computedCode, onChange]);
    return (_jsx(BaseField, Object.assign({ id: id, className: className, ariaLabel: ariaLabel, label: label, description: description, tooltip: tooltip, isRequired: isRequired, isHideTitle: isHideTitle }, { children: _jsx(Wrapper, Object.assign({ className: className }, { children: code.map((_, index) => (_jsx(StyledInput, { id: `invite-code-box-${index}`, value: code[index], isCentered: true, isDisabled: isDisabled, autoFocus: autoFocus && index === 0, onKeyDown: (e) => {
                    if (e.key === 'Backspace' && code[index] === '') {
                        e.preventDefault();
                        const prevEl = document.getElementById(`invite-code-box-${index - 1}`);
                        if (prevEl) {
                            prevEl.focus();
                            setCode((prev) => {
                                const next = [...prev];
                                next[index - 1] = '';
                                return next;
                            });
                        }
                    }
                    else {
                        if (['ArrowLeft', 'ArrowRight'].includes(e.key)) {
                            e.preventDefault();
                        }
                        const el = document.getElementById(`invite-code-box-${index}`);
                        el === null || el === void 0 ? void 0 : el.select();
                    }
                }, onClick: (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    e.currentTarget.select();
                }, onChange: (val) => {
                    setCode((prev) => {
                        let characters = val.split('');
                        const next = [...prev];
                        if (characters.length === 0)
                            characters = [''];
                        for (let i = 0; i < characters.length; i++) {
                            const char = characters[i];
                            if (!regex.test(char) && char !== '')
                                return prev;
                            let direction = 1;
                            if (char === '') {
                                next[index] = '';
                                direction = -1;
                            }
                            else {
                                next[index + i] = char.toUpperCase();
                            }
                            const nextEl = document.getElementById(`invite-code-box-${(index + 1 + i) * direction}`);
                            if (nextEl) {
                                nextEl.focus();
                                setTimeout(() => nextEl.select(), 100);
                            }
                            else {
                                if (char === '')
                                    return next;
                                const el = document.getElementById(`invite-code-box-${index + i}`);
                                el === null || el === void 0 ? void 0 : el.blur();
                            }
                        }
                        return next;
                    });
                } }, index))) })) })));
};
const Wrapper = styled.div `
  display: flex;
  gap: ${(props) => props.theme.spacing[4]};
`;
const StyledInput = styled(Input) `
  width: 40px;
`;
InviteCode.displayName = 'InviteCode';
export default InviteCode;
