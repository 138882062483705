var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// react
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useNavigate } from 'react-router-dom';
// components
import { Button, Container, ProgressBar } from "../../atoms";
import { BackButton } from "../../molecules";
// hooks
import { getColorContrast } from "../../../utils/color";
export var OnboardingActionType;
(function (OnboardingActionType) {
    OnboardingActionType["Next"] = "next";
    OnboardingActionType["Skip"] = "nextSection";
    OnboardingActionType["Back"] = "back";
    OnboardingActionType["End"] = "end";
    OnboardingActionType["Previous"] = "previous";
    OnboardingActionType["To"] = "to";
})(OnboardingActionType || (OnboardingActionType = {}));
const defaultActions = [
    {
        label: 'Back',
        onClick: OnboardingActionType.Back,
    },
    {
        label: 'Next',
        onClick: OnboardingActionType.Next,
    },
];
/**
 * OnboardingFlowPage takes the user through the onboarding flow.
 */
export const OnboardingFlow = ({ className, config, onNext, onComplete, isActionBackground = true, alignActions = 'center', isBackButton = false, }) => {
    var _a, _b, _c;
    // state
    const [index, setIndex] = useState(0);
    const [subIndex, setSubIndex] = useState(0);
    const [isNextDisabled, setIsNextDisabled] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    // refs
    const wrapperRef = useRef(null);
    // hooks
    const navigate = useNavigate();
    // memos
    const CurrentComponent = useMemo(() => {
        return config[index].Component;
    }, [index, config]);
    /**
     * actions are the buttons that are displayed at the bottom of the onboarding
     * flow. If the actions are not provided, the default actions are used.
     *
     * Special case: if the actions are set to null, no actions are displayed.
     */
    const actions = useMemo(() => {
        const subConfig = config[index].config[subIndex];
        if (!subConfig)
            return null;
        const configActions = config[index].config[subIndex].actions;
        if (configActions === null)
            return null;
        return configActions !== null && configActions !== void 0 ? configActions : defaultActions;
    }, [config, index, subIndex]);
    const progressLimit = useMemo(() => config.reduce((limit, item) => {
        return (limit +
            item.config.filter((subItem) => !subItem.isIgnoreProgress).length);
    }, 0), [config]);
    const progress = useMemo(() => {
        const progress = config.slice(0, index).reduce((total, item) => {
            return (total +
                item.config.filter((subItem) => !subItem.isIgnoreProgress).length);
        }, 0);
        return progress + subIndex;
    }, [index, subIndex, config]);
    // methods
    const handleComplete = useCallback(() => {
        setIsComplete(true);
        onComplete();
    }, [onComplete]);
    const handleClick = useCallback((actionType) => __awaiter(void 0, void 0, void 0, function* () {
        var _d, _e;
        switch (actionType) {
            case OnboardingActionType.Next:
                if (subIndex === config[index].config.length - 1) {
                    if (config[index].onBeforeNext &&
                        !(yield ((_e = (_d = config[index]).onBeforeNext) === null || _e === void 0 ? void 0 : _e.call(_d)))) {
                        return;
                    }
                    if (index >= config.length - 1) {
                        handleComplete();
                        return;
                    }
                    setIndex(Math.min(index + 1, config.length - 1));
                    setSubIndex(0);
                }
                else {
                    setSubIndex((prev) => {
                        return Math.min(prev + 1, config[index].config.length - 1);
                    });
                }
                break;
            case OnboardingActionType.Back:
                if (subIndex === 0) {
                    if (index <= 0)
                        return;
                    const prevIndex = index - 1;
                    setIndex(prevIndex);
                    setSubIndex(config[prevIndex].config.length - 1);
                }
                else {
                    setSubIndex((prev) => prev - 1);
                }
                break;
            case OnboardingActionType.Skip:
                if (index >= config.length - 1)
                    return;
                setIndex(Math.min(index + 1, config.length - 1));
                setSubIndex(0);
                break;
            case OnboardingActionType.End:
                handleComplete();
                return;
        }
    }), [index, subIndex, config, onComplete]);
    // effects
    useEffect(() => {
        var _a, _b;
        if (isComplete)
            return;
        (_b = (_a = config[index]).onStart) === null || _b === void 0 ? void 0 : _b.call(_a, handleClick);
    }, [index, config, handleClick]);
    useEffect(() => {
        config[index].isSkip && handleClick(OnboardingActionType.Skip);
    }, [config, index]);
    useEffect(() => {
        var _a, _b, _c;
        (_a = wrapperRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo({ top: 0, behavior: 'smooth' });
        onNext === null || onNext === void 0 ? void 0 : onNext((_b = config[index]) === null || _b === void 0 ? void 0 : _b.id, (_c = config[index].config[subIndex]) === null || _c === void 0 ? void 0 : _c.id);
    }, [index, subIndex]);
    return (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ style: { display: 'none' } }, { children: ((_a = config === null || config === void 0 ? void 0 : config[index]) === null || _a === void 0 ? void 0 : _a.isProgressBar) ? 'progress bar' : 'no progress bar' })), ((_b = config === null || config === void 0 ? void 0 : config[index]) === null || _b === void 0 ? void 0 : _b.isProgressBar) && (_jsx(StyledContainer, Object.assign({ isInline: true, size: 'md' }, { children: _jsxs(ProgressBarWrapper, { children: [isBackButton && _jsx(BackButton, { label: undefined, variant: 'link' }), _jsx(ProgressBar, { limit: progressLimit, progress: progress, label: 'Progress' })] }) }))), _jsx(Wrapper, Object.assign({ className: className, ref: wrapperRef }, { children: _jsx(CurrentComponent, { config: config[index].config, handleClick: handleClick, currentIndex: subIndex || 0, isNextDisabled: isNextDisabled, setIsNextDisabled: setIsNextDisabled, isComplete: isComplete }) })), actions && (_jsx(ButtonWrapper, Object.assign({ "$isActionBackground": isActionBackground, "$alignActions": alignActions, "$color": (_c = config[index].config[subIndex]) === null || _c === void 0 ? void 0 : _c.actionsBackground }, { children: actions === null || actions === void 0 ? void 0 : actions.map((action) => (_jsx(StyledButton, Object.assign({ ariaLabel: 'next', variant: action.onClick === OnboardingActionType.Next
                        ? 'primary'
                        : 'secondary', onPress: () => {
                        if (action.onClick === OnboardingActionType.To) {
                            action.to && navigate(action.to);
                            return;
                        }
                        handleClick(action.onClick);
                    }, isDisabled: (action.onClick == OnboardingActionType.Next &&
                        isNextDisabled) ||
                        action.isDisabled, iconLeft: action.iconLeft, iconRight: action.iconRight, "$isFullWidth": action.isFullWidth }, { children: action.label }), action.label))) })))] }));
};
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex: 1;
`;
const StyledContainer = styled(Container) `
  padding-top: ${(props) => props.theme.spacing[32]};
`;
const ProgressBarWrapper = styled.div `
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing[16]};
  padding-bottom: ${(props) => props.theme.spacing[24]};
`;
const ButtonWrapper = styled.div `
  display: flex;
  ${(props) => {
    switch (props.$alignActions) {
        case 'left':
            return css `
          justify-content: flex-start;
        `;
        case 'center':
            return css `
          justify-content: center;
        `;
        case 'right':
            return css `
          justify-content: flex-end;
        `;
        default:
            return css `
          justify-content: center;
        `;
    }
}}
  gap: ${(props) => props.theme.spacing[16]};

  padding: ${(props) => props.theme.spacing[24]};
  padding-bottom: ${(props) => props.theme.spacing[36]};

  padding: ${(props) => props.theme.spacing[16]};
  border-top: 1px solid
    ${(props) => {
    if (!props.$color)
        return props.theme.color.neutral[50];
    if (getColorContrast(props.$color) === '#000000') {
        return props.theme.color.neutral[70];
    }
    else {
        return props.theme.color.neutral[50];
    }
}};
  background: ${(props) => props.$color || props.theme.color.neutral[10]};
  ${(props) => !props.$isActionBackground &&
    !props.$color &&
    css `
      background: none;
    `}
  z - index: 1;
`;
const StyledButton = styled(Button) `
  min-width: 120px;
  max-width: 600px;
  ${(props) => props.$isFullWidth &&
    css `
      width: 100%;
    `}
`;
/**
 * toGenericOnboarding converts a component which uses GenericFlowProps into
 * a type that can be consumed by OnboardingFlow.
 */
export const toGenericOnboarding = (component) => {
    return component;
};
OnboardingFlow.displayName = 'OnboardingFlow';
export default OnboardingFlow;
