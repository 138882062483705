import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useState } from 'react';
import styled from 'styled-components';
// components
import { Button } from "../../atoms";
import { EditorFields } from "../EditorFields/EditorFields";
/**
 * EditorReveal is a component that reveals the editor.
 */
export const EditorReveal = ({ fields, onSubmit, revealLabel = 'Reveal', submitLabel = 'Submit', revealVariant = 'primary', submitVariant = 'primary', revealIconLeft, }) => {
    // state
    const [value, setValue] = useState({});
    const [isEditor, setIsEditor] = useState(false);
    const [isValid, setIsValid] = useState(false);
    return (_jsx(Wrapper, { children: isEditor ? (_jsxs(Form, Object.assign({ onSubmit: (e) => {
                e.preventDefault();
                onSubmit(value);
            } }, { children: [_jsx(EditorFields, { fields: fields, value: value, onChange: setValue, setIsValid: setIsValid }), _jsx(Button, Object.assign({ ariaLabel: submitLabel, type: 'submit', variant: submitVariant, isFullWidth: true, isDisabled: !isValid }, { children: submitLabel }))] }))) : (_jsx(Button, Object.assign({ ariaLabel: revealLabel, onPress: () => setIsEditor(true), variant: revealVariant, iconLeft: revealIconLeft, isFullWidth: true }, { children: revealLabel }))) }));
};
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[16]};
  width: 100%;
`;
const Form = styled.form `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[16]};
`;
EditorReveal.displayName = 'EditorReveal';
export default EditorReveal;
