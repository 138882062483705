import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import styled, { css } from 'styled-components';
// components
import { Background, Logo, Memphis } from "../../atoms";
// hooks
import { useWindowSizeBreakpoints } from "../../../hooks";
// theme
import theme from "../../../theme";
// assets
// @transform-path ../../../../../../src/assets/dashboard/header.jpg
import headerImage from "../../../../../../src/assets/dashboard/header.jpg";
/**
 * MemphisHeader displays the Memphis header.
 */
export const MemphisHeader = ({ className, variant = 'happy-family-1', }) => {
    // hooks
    const { isMd } = useWindowSizeBreakpoints();
    return (_jsx(Background, Object.assign({ src: headerImage, className: className }, { children: _jsx(Background, Object.assign({ color: theme.color.neutral[100], gradient: 'top' }, { children: _jsxs(HeaderWrapper, { children: [_jsx(Logo, { color: 'white', size: isMd ? 'xl' : 'lg' }), _jsx(Memphis, { variant: variant })] }) })) })));
};
const HeaderWrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  max-height: 100%;
  gap: ${(props) => props.theme.spacing[16]};
  padding: ${(props) => props.theme.spacing[32]};
  padding-bottom: 0;

  > *:last-child {
    flex-shrink: 1;
    flex-grow: 0;
    flex-basis: auto;
    overflow: hidden;
  }

  ${(props) => props.theme.breakpoint.md(css `
      padding: ${props.theme.spacing[64]};
      padding-bottom: 0;
    `)}
`;
MemphisHeader.displayName = 'MemphisHeader';
export default MemphisHeader;
