import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import styled, { css } from 'styled-components';
import { useMemo } from 'react';
// components
import { CareHomeCard } from "../../molecules";
import { Card, Label, Title } from "../../atoms";
export const CareHomeOverview = ({ shortlist, shortlistData, onMarkSecured, }) => {
    // helper memos
    const formatAddress = useMemo(() => (address) => {
        const parts = [
            address.streetAddress1,
            address.city,
            address.district,
            address.county,
            address.region,
            address.country,
            address.postcode,
        ].filter(Boolean); // Filter out undefined or null values
        return parts.join(', ');
    }, []);
    return (_jsxs(StyledArticleOverview, { children: [_jsxs(Header, { children: [_jsx(Title, Object.assign({ isNoMargin: true, size: 'sm' }, { children: "Your Shortlist" })), shortlistData.items && (_jsx(StyledCard, Object.assign({ color: 'yellow', isBordered: false, elevation: 0, isPaddingSm: true }, { children: _jsx(StyledLabel, { id: 'shortlist-label', text: 'Your Care Expert has chosen the below care services to match your care needs.' }) })))] }), _jsx(Content, { children: shortlistData.items && shortlistData.items.length > 0 ? (shortlistData.items.map((item) => {
                    var _a;
                    return (_jsx(CareHomeCard, { regulatoryID: item.regulatoryID, headerImageUrl: (_a = item.photos) === null || _a === void 0 ? void 0 : _a.medium, name: item.name, address: item.address
                            ? formatAddress(item.address)
                            : 'Address not available', rating: item.rating ? item.rating.overallRating : 'No rating available', phone: item.enquiryPhone, specialisms: item.specialisms, available: item.availability, notes: item.notes, isSecured: item.providerID === shortlist.securedProviderID, onMarkSecured: () => item.providerID && (onMarkSecured === null || onMarkSecured === void 0 ? void 0 : onMarkSecured(item.providerID)) }, item.providerID));
                })) : (_jsx(StyledText, { children: "No care homes available in your shortlist." })) })] }));
};
const StyledArticleOverview = styled.div `
  display: flex;
  flex-direction: column;
  padding-bottom: ${(props) => props.theme.spacing[8]};
  padding-left: ${(props) => props.theme.spacing[4]};
  padding-right: ${(props) => props.theme.spacing[4]};
  overflow-x: visible;
  ${(props) => props.theme.breakpoint.sm(css `
      overflow-x: hidden;
    `)}
`;
const Header = styled.div `
  display: flex;
  flex-direction: column;
`;
const Content = styled.div `
  display: flex;
  gap: ${(props) => props.theme.spacing[16]};
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: ${(props) => props.theme.spacing[8]};
`;
const StyledText = styled.div `
  font: ${(props) => props.theme.typography.body.md};
  color: ${(props) => props.theme.color.neutral[90]};
`;
const StyledLabel = styled(Label) `
  font: ${(props) => props.theme.typography.body.md.medium};
`;
const StyledCard = styled(Card) `
  margin: ${(props) => props.theme.spacing[24]} 0;
`;
CareHomeOverview.displayName = 'CareHomeOverview';
export default CareHomeOverview;
