export const elevation = {
    0: 'none',
    1: '0px 1px 0px rgba(16, 24, 40, 0.21)',
    2: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
    3: '0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)',
    4: '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
    5: '0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)',
    6: '0px 24px 48px -12px rgba(16, 24, 40, 0.18)',
    navBar: '0px -4px 8px -2px rgba(16, 24, 40, 0.10), 0px -2px 4px -2px rgba(16, 24, 40, 0.06)',
};
export default elevation;
