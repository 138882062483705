// components
import { ActionPlan as ActionPlanPage } from '@karehero/llama';

// hooks
import { useActionPlan } from 'hooks/useActionPlan';

/**
 * ActionPlan shows the action plan page.
 */
const ActionPlan = () => {
  // hooks
  const { allActionPlanItems, actionPlanCategories, markActionDismissed } =
    useActionPlan();

  return (
    <ActionPlanPage
      actionPlanItems={allActionPlanItems || []}
      categories={actionPlanCategories || []}
      onDismiss={markActionDismissed}
    />
  );
};

export default ActionPlan;
