import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import styled from 'styled-components';
// components
import { ActionPlanHeader, Container, Title, } from "../../atoms";
import { ActionPlanList } from "../../organisms";
/**
 * ActionPlanCategory displays a list of action plan items.
 */
export const ActionPlanCategory = ({ className, actionPlanCategory, }) => {
    return (_jsxs(Wrapper, Object.assign({ className: className }, { children: [_jsx(ActionPlanHeader, { color: actionPlanCategory === null || actionPlanCategory === void 0 ? void 0 : actionPlanCategory.color, memphis: actionPlanCategory === null || actionPlanCategory === void 0 ? void 0 : actionPlanCategory.headerMemphis }), _jsx(Container, Object.assign({ size: 'md' }, { children: actionPlanCategory && (_jsxs(Body, { children: [_jsxs(TitleWrapper, { children: [_jsx(Title, Object.assign({ size: 'sm', isNoMargin: true }, { children: actionPlanCategory === null || actionPlanCategory === void 0 ? void 0 : actionPlanCategory.title })), _jsx(SubTitle, { children: actionPlanCategory === null || actionPlanCategory === void 0 ? void 0 : actionPlanCategory.subTitle })] }), _jsx(ActionPlanList, { actionPlanCategory: actionPlanCategory, actionPlanItems: actionPlanCategory === null || actionPlanCategory === void 0 ? void 0 : actionPlanCategory.actionPlanItems })] })) }))] })));
};
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[24]};
  padding-bottom: ${(props) => props.theme.spacing[48]};
`;
const TitleWrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[16]};
`;
const SubTitle = styled.div `
  font: ${(props) => props.theme.typography.body.md.regular};
  color: ${(props) => props.theme.color.neutral[90]};
`;
const Body = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[24]};
`;
ActionPlanCategory.displayName = 'ActionPlanCategory';
export default ActionPlanCategory;
