var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { css } from 'styled-components';
// react-aria
import { useTextField } from 'react-aria';
// components
import { BaseField } from "../BaseField/BaseField";
import { Icon } from "../Icon/Icon";
/**
 * Input allows users to enter and edit text.
 */
export const Input = (_a) => {
    var { id = 'input', className, label, description, iconLeft, iconRight, size = 'md', type = 'text', placeholder, ariaLabel, tooltip, value, onChange, validate = () => true, isDisabled = false, isRequired = false, isHideTitle = false, autoFocus = false, minLength, maxLength, onClick, isCentered, onKeyDown } = _a, props = __rest(_a, ["id", "className", "label", "description", "iconLeft", "iconRight", "size", "type", "placeholder", "ariaLabel", "tooltip", "value", "onChange", "validate", "isDisabled", "isRequired", "isHideTitle", "autoFocus", "minLength", "maxLength", "onClick", "isCentered", "onKeyDown"]);
    // state
    const [isError, setIsError] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    // hooks/
    const ref = useRef(null);
    let { inputProps } = useTextField(Object.assign(Object.assign({}, props), { isDisabled,
        placeholder,
        id,
        type,
        autoFocus, minLength: minLength, maxLength: maxLength, onKeyDown, 'aria-label': ariaLabel || label }), ref);
    // effects
    useEffect(() => {
        setIsError(!validate(value) && ((value === null || value === void 0 ? void 0 : value.length) || 0) > 0);
    }, [value, validate]);
    return (_jsx(BaseField, Object.assign({ id: id, className: className, ariaLabel: ariaLabel, label: label, description: description, tooltip: tooltip, isRequired: isRequired, isHideTitle: isHideTitle }, { children: _jsxs(StyledInputContainer, Object.assign({ "$size": size, "$isError": isError, "$isDisabled": isDisabled }, { children: [iconLeft && (_jsx(StyledIcon, Object.assign({ size: size }, { children: _jsx(Icon, { icon: iconLeft, fontSize: size === 'sm' ? 12 : 16 }) }))), _jsx(StyledInput, Object.assign({}, inputProps, { ref: ref, value: value, onChange: (e) => onChange === null || onChange === void 0 ? void 0 : onChange(e.target.value), onClick: onClick, "aria-label": ariaLabel, "$inputSize": size, "$isCentered": isCentered })), !isError && iconRight && (_jsx(StyledIcon, Object.assign({ size: size, isRight: true }, { children: _jsx(Icon, { icon: iconRight, fontSize: size === 'sm' ? 12 : 16 }) }))), isError && (_jsx(StyledErrorIcon, Object.assign({ size: size }, { children: _jsx(Icon, { icon: 'exclamation-circle', fontSize: size === 'sm' ? 12 : 16 }) })))] })) })));
};
const StyledInputContainer = styled.div `
  // default styles
  display: inline-flex;
  align-items: center;
  width: 100%;

  color: ${(props) => props.theme.color.neutral[90]};
  background: ${(props) => props.theme.color.neutral[10]};
  padding: 0;
  border: 1px solid ${(props) => props.theme.color.neutral[60]};
  border-radius: ${(props) => (props.$size === 'sm' ? '4px' : '8px')};

  &:focus-within {
    outline: 3px solid ${(props) => props.theme.color.primary[20]};
    border: 1px solid ${(props) => props.theme.color.primary[50]};
  }

  // error styles
  ${(props) => props.$isError &&
    css `
      border: 1px solid ${props.theme.color.danger[40]};
      background: ${props.theme.color.neutral[30]};
      &:focus-within {
        border: 1px solid ${props.theme.color.danger[40]};
      }
    `}

  // disabled styles
  ${(props) => props.$isDisabled &&
    css `
      background: ${props.theme.color.neutral[30]};
      color: ${props.theme.color.neutral[50]};
      border: 1px solid ${props.theme.color.neutral[50]};
    `}
`;
const StyledInput = styled.input `
  min-width: 0;
  width: 100%;
  color: inherit;
  border: none;
  outline: none;
  background: none;
  margin: 0;
  caret-color: ${(props) => props.theme.color.primary[50]};
  text-align: ${(props) => (props.$isCentered ? 'center' : 'left')};

  ${(props) => {
    switch (props.$inputSize) {
        case 'sm':
            return css `
          font: ${props.theme.typography.body.sm.regular};
          padding: ${props.theme.spacing[4]} ${props.theme.spacing[12]};
        `;
        case 'md':
            return css `
          font: ${props.theme.typography.body.md.medium};
          padding: ${props.theme.spacing[12]};
        `;
    }
}}

  &::placeholder {
    color: ${(props) => props.theme.color.neutral[50]};
  }
`;
const StyledIcon = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  color: ${(props) => props.theme.color.neutral[90]};

  ${(props) => {
    switch (props.size) {
        case 'sm':
            return css `
          padding: ${props.theme.spacing[4]} ${props.theme.spacing[12]};
        `;
        case 'md':
            return css `
          padding: ${props.theme.spacing[12]};
        `;
    }
}}
  ${(props) => props.isRight
    ? css `
          padding-left: 0;
        `
    : css `
          padding-right: 0;
        `}
`;
const StyledErrorIcon = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  color: ${(props) => props.theme.color.danger[40]};
  ${(props) => {
    switch (props.size) {
        case 'sm':
            return css `
          padding: ${props.theme.spacing[4]} ${props.theme.spacing[12]};
        `;
        case 'md':
            return css `
          padding: ${props.theme.spacing[12]};
        `;
    }
}}
  padding-left: 0;
`;
Input.componentName = 'Input';
export default Input;
