import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
// components
import { Button } from "..";
// glidejs
import Glide from '@glidejs/glide';
import '@glidejs/glide/dist/css/glide.core.min.css';
/**
 * CarouselResponsive shows a responsive carousel.
 */
export const CarouselResponsive = ({ className, items = [], perView = 1, }) => {
    // state
    const [gliderRef, setGliderRef] = useState(null);
    const [index, setIndex] = useState(0);
    const handleRef = useCallback((node) => {
        if (node)
            setGliderRef(node);
    }, []);
    useEffect(() => {
        if (!gliderRef)
            return;
        const glide = new Glide(gliderRef, {
            peek: 0,
            perView,
        });
        glide.on('run', () => {
            setIndex(glide.index);
        });
        glide.mount();
        return () => {
            glide.destroy();
        };
    }, [gliderRef, perView]);
    return (_jsxs(Wrapper, Object.assign({ className: className, id: 'glide', ref: handleRef }, { children: [_jsx(GlideTrack, Object.assign({ className: 'glide__track', "data-glide-el": 'track' }, { children: _jsx(GlideSlides, Object.assign({ className: 'glide__slides' }, { children: items.map((item) => (_jsx(Slide, Object.assign({ className: 'glide__slide' }, { children: item })))) })) })), _jsxs(Actions, Object.assign({ className: 'glide__arrows', "data-glide-el": 'controls' }, { children: [_jsx(Button, { className: 'glide__arrow glide__arrow--left', "data-glide-dir": '<', ariaLabel: 'carousel left', iconLeft: 'chevron-left', variant: 'link', size: 'lg', isDisabled: index === 0 }), _jsx(Button, { className: 'glide__arrow glide__arrow--right', "data-glide-dir": '>', ariaLabel: 'carousel right', iconLeft: 'chevron-right', variant: 'link', size: 'lg', isDisabled: index === items.length - 1 })] }))] })));
};
const Wrapper = styled.div `
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 ${(props) => props.theme.spacing[24]};
  overflow: visible;
`;
const GlideTrack = styled.div `
  overflow: visible;
`;
const GlideSlides = styled.ul `
  overflow: visible;
`;
const Slide = styled.li `
  display: flex;
  justify-content: center;
  overflow: visible;
`;
const Actions = styled.div `
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
`;
CarouselResponsive.displayName = 'CarouselResponsive';
export default CarouselResponsive;
