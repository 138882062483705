// react
import { useEffect, useMemo, useState } from 'react';
// theme
import theme from "../theme";
/**
 * useWindowSize is a hook that returns the current window size.
 */
export const useWindowSize = () => {
    // state
    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);
    // effect
    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);
    return windowSize;
};
export const useWindowSizeBreakpoints = () => {
    // hooks
    const windowSize = useWindowSize();
    // memos
    const isXs = useMemo(() => windowSize[0] < theme.breakpointValues.smValue, [windowSize]);
    const isSm = useMemo(() => windowSize[0] >= theme.breakpointValues.smValue, [windowSize]);
    const isMd = useMemo(() => windowSize[0] >= theme.breakpointValues.mdValue, [windowSize]);
    const isLg = useMemo(() => windowSize[0] >= theme.breakpointValues.lgValue, [windowSize]);
    const isXl = useMemo(() => windowSize[0] >= theme.breakpointValues.xlValue, [windowSize]);
    return {
        isXs,
        isSm,
        isMd,
        isLg,
        isXl,
    };
};
