import api from '.';

import {
  Article,
  ArticleCategory,
  SanityActionPlan,
  Service,
  Webinar,
} from '@karehero/models';

export const cmsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllArticleFiltered: builder.query<Article[], void>({
      query: () => '/cms/article/filtered',
      keepUnusedDataFor: 0,
    }),
    getAllArticleBookmark: builder.query<Article[], void>({
      query: () => '/cms/article/bookmark',
      providesTags: ['ArticleBookmarks'],
    }),
    bookmarkArticle: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `/cms/article/bookmark/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['ArticleBookmarks'],
    }),
    getAllArticleLibrary: builder.query<ArticleCategory[], void>({
      query: () => '/cms/article/library',
      keepUnusedDataFor: 0,
    }),
    getAllArticle: builder.query<Article[], void>({
      query: () => '/cms/article',
      keepUnusedDataFor: 0,
    }),
    getArticle: builder.query<Article, string>({
      query: (slug: string) => `/cms/article/${slug}`,
      keepUnusedDataFor: 0,
    }),
    getArticleById: builder.query<Article, string>({
      query: (id: string) => `/cms/article/id/${id}`,
      keepUnusedDataFor: 0,
    }),
    getAllArticleByTag: builder.query<Article[], string>({
      query: (tag: string) => `/cms/article/tag?tag=${tag}`,
      keepUnusedDataFor: 0,
    }),
    getAllSuggestedArticle: builder.query<Article[], void>({
      query: () => `/cms/article/suggested`,
      keepUnusedDataFor: 0,
    }),
    searchArticle: builder.query<Article[], string>({
      query: (query: string) => `/cms/article/search?query=${query}`,
      keepUnusedDataFor: 0,
    }),
    getAllService: builder.query<Service[], void>({
      query: () => '/cms/service',
      keepUnusedDataFor: 0,
    }),
    getService: builder.query<Service, string>({
      query: (slug: string) => `/cms/service/${slug}`,
      keepUnusedDataFor: 0,
    }),
    getAllWebinar: builder.query<Webinar[], void>({
      query: () => '/cms/webinar',
      keepUnusedDataFor: 0,
    }),
    getWebinar: builder.query<Webinar, string>({
      query: (id: string) => `/cms/webinar/${id}`,
      keepUnusedDataFor: 0,
    }),
    getActionPlanSanity: builder.query<SanityActionPlan, string>({
      query: (slug: string) => `/cms/action-plan/${slug}`,
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useGetAllArticleQuery,
  useGetAllArticleLibraryQuery,
  useLazyGetArticleQuery,
  useLazyGetArticleByIdQuery,
  useGetAllServiceQuery,
  useLazyGetServiceQuery,
  useLazyGetAllArticleByTagQuery,
  useLazySearchArticleQuery,
  useGetAllSuggestedArticleQuery,
  useGetAllWebinarQuery,
  useLazyGetWebinarQuery,
  useGetAllArticleBookmarkQuery,
  useBookmarkArticleMutation,
  useLazyGetActionPlanSanityQuery,
  useGetAllArticleFilteredQuery,
} = cmsApi;

export default cmsApi;
