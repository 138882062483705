import { jsx as _jsx } from "react/jsx-runtime";
// react
import styled, { css } from 'styled-components';
// components
import { BaseField } from "../BaseField/BaseField";
/**
 * TextArea is a text input field that allows for multiple lines of text.
 */
export const TextArea = ({ className, placeholder, label, description, tooltip, isRequired, isHideTitle, size = 'md', value, isDisabled = false, onChange, }) => {
    return (_jsx(BaseField, Object.assign({ label: label, description: description, tooltip: tooltip, isRequired: isRequired, isHideTitle: isHideTitle }, { children: _jsx(StyledTextArea, { className: className, placeholder: placeholder, value: value, onChange: (val) => onChange === null || onChange === void 0 ? void 0 : onChange(val.target.value), "$size": size, disabled: isDisabled }) })));
};
const StyledTextArea = styled.textarea `
  // default styles
  display: inline-flex;
  align-items: center;
  width: 100%;
  vertical-align: top;

  min-height: 50px;
  padding: ${(props) => props.theme.spacing[12]};
  font: ${(props) => props.theme.typography.body.md.medium};
  ${(props) => props.$size === 'sm' &&
    css `
      min-height: 10px;
      padding: ${(props) => props.theme.spacing[8]};
      font: ${(props) => props.theme.typography.body.sm.medium};
    `}

  color: ${(props) => props.theme.color.neutral[90]};
  background: ${(props) => props.theme.color.neutral[10]};
  border: 1px solid ${(props) => props.theme.color.neutral[60]};
  border-radius: 8px;

  resize: vertical;

  &:focus-within {
    outline: 3px solid ${(props) => props.theme.color.primary[20]};
    border: 1px solid ${(props) => props.theme.color.primary[50]};
  }

  &::placeholder {
    color: ${(props) => props.theme.color.neutral[50]};
  }
`;
TextArea.displayName = 'TextArea';
export default TextArea;
