import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// react
import styled from 'styled-components';
/**
 * Divider displays a horizontal line with text in the middle.
 */
export const Divider = ({ className, isOr = true, color = 'light', }) => {
    return (_jsxs(StyledDivider, Object.assign({ className: className, "$color": color }, { children: [_jsx(DividerLine, { "$color": color }), isOr && (_jsxs(_Fragment, { children: ["OR", _jsx(DividerLine, { "$color": color })] }))] })));
};
const StyledDivider = styled.div `
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing[8]};
  width: 100%;
  color: ${(props) => {
    switch (props.$color) {
        case 'light':
            return props.theme.color.neutral[70];
        case 'dark':
            return props.theme.color.neutral[90];
    }
}};
  font: ${(props) => props.theme.typography.body.xs.regular};
`;
const DividerLine = styled.div `
  border-bottom: 1px solid
    ${(props) => {
    switch (props.$color) {
        case 'light':
            return props.theme.color.neutral[50];
        case 'dark':
            return props.theme.color.neutral[70];
    }
}};
  flex: 1;
`;
Divider.displayName = 'Divider';
export default Divider;
