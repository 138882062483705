import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
// react
import styled from 'styled-components';
// components
import { Button, CarouselResponsive, Container, Divider, ProgressCircle, Title, } from "../../atoms";
import { ProfileSummary } from "../../molecules";
import { SuggestedArticles, } from "../../organisms";
// hooks
import { useWindowSizeBreakpoints } from "../../../hooks";
/**
 * Dashboard is the page for the care profile.
 */
export const Dashboard = ({ suggestedArticles, account, actionPlanCategories, }) => {
    var _a, _b, _c, _d;
    // hooks
    const { isMd } = useWindowSizeBreakpoints();
    return (_jsxs(Wrapper, { children: [_jsx(Container, { children: _jsxs(Header, { children: [_jsxs(Title, Object.assign({ size: 'sm', isNoMargin: true }, { children: ["Welcome, ", `${account === null || account === void 0 ? void 0 : account.firstName}`] })), _jsxs(DashboardHeader, { children: [_jsx(ProfileSummary, { fallback: '', name: 'Ready to help', role: 'Your Care Expert', size: 'sm', imgSrc: '/images/care-expert.jpg' }), _jsx(Button, Object.assign({ ariaLabel: 'chat', size: 'sm', variant: 'primary', iconLeft: 'message', to: '/messenger' }, { children: "Chat" }))] })] }) }), _jsx(Divider, { isOr: false }), _jsx(Container, { children: _jsxs(Body, { children: [_jsx(CarouselResponsive, { perView: isMd ? 2 : 1, items: [
                                _jsx(ProgressCircle, { title: 'Find Care', description: 'KareHero finds your care', to: '/action-plan/category/find-care', color: 'orange', limit: ((_a = actionPlanCategories === null || actionPlanCategories === void 0 ? void 0 : actionPlanCategories.find((category) => category.id === 'find-care')) === null || _a === void 0 ? void 0 : _a.totalItems) || 0, progress: ((_b = actionPlanCategories === null || actionPlanCategories === void 0 ? void 0 : actionPlanCategories.find((category) => category.id === 'find-care')) === null || _b === void 0 ? void 0 : _b.completedItems) || 0 }),
                                _jsx(ProgressCircle, { title: 'Cover Costs', description: 'Karehero helps cover costs', to: '/action-plan/category/cover-costs', color: 'green', limit: ((_c = actionPlanCategories === null || actionPlanCategories === void 0 ? void 0 : actionPlanCategories.find((category) => category.id === 'cover-costs')) === null || _c === void 0 ? void 0 : _c.totalItems) || 0, progress: ((_d = actionPlanCategories === null || actionPlanCategories === void 0 ? void 0 : actionPlanCategories.find((category) => category.id === 'cover-costs')) === null || _d === void 0 ? void 0 : _d.completedItems) || 0 }),
                            ] }), _jsx(SuggestedArticles, Object.assign({}, suggestedArticles))] }) })] }));
};
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[24]};
  padding-bottom: ${(props) => props.theme.spacing[48]};
`;
const Body = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[48]};
`;
const Header = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[16]};
  position: relative;
  padding-top: ${(props) => props.theme.spacing[24]};
`;
const DashboardHeader = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: ${(props) => props.theme.spacing[16]};
`;
Dashboard.displayName = 'Dashboard';
export default Dashboard;
