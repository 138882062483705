// components
import { Messenger as MessengerPage } from '@karehero/llama';

// store
import { useSelector } from 'store';
import { getAccount } from 'store/slices/iamSlice';

/**
 * Chat is the dashboard page.
 */
const Chat = () => {
  // hooks
  const account = useSelector(getAccount);

  // memos
  return (
    <MessengerPage
      kindeEmail={account?.kindeEmail}
      token={account?.hubSpotIdentificationToken}
    />
  );
};

export default Chat;
