import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import styled, { css } from 'styled-components';
// components
import { Container, Memphis } from "..";
import { BackButton } from "../../molecules";
/**
 * ActionPlanHeader displays a header for the action plan category.
 */
export const ActionPlanHeader = ({ className, color, isBack = true, memphis = 'elderly-couple-1', }) => {
    return (_jsx(Header, Object.assign({ className: className, color: color || 'grey' }, { children: _jsx(Container, { children: _jsxs(HeaderWrapper, { children: [isBack && _jsx(BackButton, { isNoMargin: true }), _jsx(MemphisWrapper, { children: _jsx(Memphis, { size: 'md', variant: memphis }) })] }) }) })));
};
const HeaderWrapper = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  gap: ${(props) => props.theme.spacing[16]};
`;
const Header = styled.div `
  position: relative;
  display: flex;
  align-items: center;

  background: ${(props) => { var _a; return (_a = props.theme.color.actionPlanCards[props.color]) === null || _a === void 0 ? void 0 : _a.secondary; }};
  color: ${(props) => { var _a; return (_a = props.theme.color.actionPlanCards[props.color]) === null || _a === void 0 ? void 0 : _a.primary; }};
  height: 100px;
  ${(props) => props.theme.breakpoint.sm(css `
      height: 150px;
    `)}
  overflow: hidden;
`;
const MemphisWrapper = styled.div `
  display: flex;
  align-items: flex-end;

  height: 100%;
  max-height: 100%;
  flex-shrink: 1;
  flex-grow: 0;
  flex-basis: auto;

  & > * {
    max-height: 100%;
    flex-shrink: 1;
    flex-grow: 0;
    flex-basis: auto;
  }
`;
ActionPlanHeader.displayName = 'ActionPlanHeader';
export default ActionPlanHeader;
