import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { css } from 'styled-components';
/**
 * Title is the title of a page.
 */
export const Title = ({ className, children, isNoMargin = false, isCenter = false, size = 'md', }) => {
    return (_jsx(StyledTitle, Object.assign({ className: className, "$isNoMargin": isNoMargin, "$isCenter": isCenter, "$size": size }, { children: children })));
};
const StyledTitle = styled.div `
  font: ${(props) => {
    switch (props.$size) {
        case 'sm':
            return props.theme.typography.heading['3xl'];
        case 'md':
        default:
            return props.theme.typography.heading['5xl'];
    }
}};
  color: ${(props) => props.theme.color.primary[80]};
  ${(props) => !props.$isNoMargin &&
    css `
      margin-bottom: ${props.theme.spacing[36]};
    `}
  ${(props) => props.$isCenter && 'text-align: center;'}
`;
Title.displayName = 'Title';
export default Title;
