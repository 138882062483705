export const shortlistItems = [
    {
        id: '1',
        title: 'We arrange visit or assessment',
        subTitle: 'We arrange visit or assessment',
        isComplete: false,
        order: 4,
    },
    {
        id: '2',
        title: 'Review best fit with Care Expert',
        subTitle: 'Review best fit with Care Expert',
        isComplete: false,
        order: 4,
    },
    {
        id: '3',
        title: 'We will secure care on your behalf',
        subTitle: 'We will secure care on your behalf',
        isComplete: false,
        order: 4,
    },
];
