import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useEffect } from 'react';
import styled, { css } from 'styled-components';
// components
import { CarouselIndicator, Container, Title, } from "../../atoms";
import { MemphisHeader } from "../../molecules";
import { HorizontalSplit } from "../../templates";
import { toGenericOnboarding, } from "../../pages/OnboardingFlow/OnboardingFlow";
const memphisHeaderImages = [
    'happy-family-1',
    'elderly-couple-1',
    'elderly-couple-2',
];
/**
 * OnboardingCarousel renders the carousel onboarding views.
 */
export const OnboardingCarousel = toGenericOnboarding(({ config, currentIndex = 0, setIsNextDisabled, }) => {
    var _a, _b;
    // effects
    useEffect(() => {
        setIsNextDisabled(false);
    }, [setIsNextDisabled]);
    return (_jsx(Main, { children: _jsx(HorizontalSplit, { topContent: _jsx(MemphisHeader, { variant: memphisHeaderImages[currentIndex % 3] }), bottomContent: _jsx(StyledContainer, Object.assign({ size: 'sm' }, { children: _jsxs(Wrapper, { children: [_jsxs(MainWrapper, { children: [_jsx(StyledTitle, { children: (_a = config[currentIndex]) === null || _a === void 0 ? void 0 : _a.subTitle }), _jsx(Text, { children: (_b = config[currentIndex]) === null || _b === void 0 ? void 0 : _b.text })] }), _jsx(IndicatorWrapper, { children: _jsx(CarouselIndicator, { total: config.length, index: currentIndex, color: 'light' }) })] }) })) }) }));
});
const StyledContainer = styled(Container) `
  overflow-x: hidden;
`;
const Main = styled.div `
  position: relative;
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.color.cards.yellow.tertiary};
`;
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding-top: ${(props) => props.theme.spacing[24]};
  gap: ${(props) => props.theme.spacing[24]};
  height: 100%;

  ${(props) => props.theme.breakpoint.md(css `
      justify-content: flex-start;
      gap: ${props.theme.spacing[24]};
    `)};
`;
const MainWrapper = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${(props) => props.theme.spacing[32]};
  flex: 1;
`;
const IndicatorWrapper = styled.div `
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: ${(props) => props.theme.spacing[32]};
`;
const StyledTitle = styled(Title) `
  margin: 0;
  color: ${(props) => props.theme.color.neutral[100]};
  text-align: center;
`;
const Text = styled.div `
  font: ${(props) => props.theme.typography.body.lg.regular};
  color: ${(props) => props.theme.color.neutral[100]};
  text-align: center;

  ${(props) => props.theme.breakpoint.md(css `
      font: ${(props) => props.theme.typography.body.xl.regular};
    `)}
`;
OnboardingCarousel.displayName = 'OnboardingCarousel';
export default toGenericOnboarding(OnboardingCarousel);
