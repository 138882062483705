import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
// fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// theme
import { theme } from "../../../theme";
// utils
import { getColorContrast } from "../../../utils/color";
/**
 * Icon displays an icon.
 */
export const Icon = ({ className, icon, size, fontSize, isFlip, type = 'fal', fill, isElevation, }) => {
    // memos
    const Wrapper = useMemo(() => {
        if (fill) {
            return Circle;
        }
        return React.Fragment;
    }, [fill]);
    const padding = useMemo(() => {
        let padding = '16px';
        switch (size) {
            case '2xs':
            case 'xs':
                padding = theme.spacing[4];
                break;
            case 'sm':
                padding = theme.spacing[8];
                break;
            case 'lg':
                padding = theme.spacing[12];
                break;
            case 'xl':
                padding = theme.spacing[16];
                break;
            case '2xl':
            case '2x':
                padding = theme.spacing[20];
                break;
            case '1x':
                padding = theme.spacing[8];
                break;
            case '3x':
                padding = theme.spacing[24];
                break;
            case '4x':
                padding = theme.spacing[36];
                break;
            case '5x':
            case '6x':
            case '7x':
            case '8x':
            case '9x':
            case '10x':
                padding = theme.spacing[48];
                break;
            default:
                padding = theme.spacing[8];
                break;
        }
        if (fontSize) {
            if (fontSize < 12) {
                padding = theme.spacing[4];
            }
            else if (fontSize < 24) {
                padding = theme.spacing[8];
            }
            else if (fontSize < 32) {
                padding = theme.spacing[12];
            }
            else if (fontSize < 44) {
                padding = theme.spacing[16];
            }
            else if (fontSize < 64) {
                padding = theme.spacing[20];
            }
            else if (fontSize < 82) {
                padding = theme.spacing[24];
            }
            else if (fontSize < 112) {
                padding = theme.spacing[36];
            }
            else if (fontSize < 192) {
                padding = theme.spacing[48];
            }
            else if (fontSize >= 192) {
                padding = theme.spacing[64];
            }
        }
        return padding;
    }, [size, fontSize]);
    const props = useMemo(() => {
        if (!fill)
            return {};
        return {
            $color: fill,
            $padding: padding,
            $isElevation: isElevation,
        };
    }, [fill, padding, isElevation]);
    return (_jsxs(Wrapper, Object.assign({}, props, { children: [fill && (_jsx(StyledFontAwesomeIcon, { className: className, icon: ['fal', 'star'], size: size, fontSize: fontSize, "$isHidden": true })), _jsx(StyledFontAwesomeIcon, { className: className, icon: [type, icon], flip: isFlip ? 'horizontal' : undefined, size: size, fontSize: fontSize, "$isFill": !!fill })] })));
};
const Circle = styled.div `
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.$color};
  color: ${(props) => getColorContrast(props.$color || '#ff0000')};
  border-radius: 99999px;
  padding: ${(props) => props.$padding};
  aspect-ratio: 1/1;
  width: min-content;
  ${(props) => props.$isElevation &&
    css `
      box-shadow: ${props.theme.elevation[4]};
    `}
`;
const StyledFontAwesomeIcon = styled(FontAwesomeIcon) `
  font-size: ${(props) => props.$fontSize}px;
  ${(props) => props.$isFill &&
    css `
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `}
  ${(props) => props.$isHidden && 'visibility: hidden;'}
`;
Icon.displayName = 'Icon';
export default Icon;
