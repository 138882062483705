import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useCallback, useEffect, useMemo } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
/**
 * HubSpotChat shows the HubSpot chat widget.
 */
export const HubSpotChat = ({ kindeEmail, token }) => {
    // memos
    const hubspotTrackingURL = useMemo(() => {
        var _a, _b;
        const env = (_a = process.env.REACT_APP_BUILD_ENVIRONMENT) !== null && _a !== void 0 ? _a : 'local';
        const urls = {
            local: 'https://js-eu1.hs-scripts.com/142770276.js',
            development: 'https://js-eu1.hs-scripts.com/142770276.js',
            staging: 'https://js-eu1.hs-scripts.com/142770276.js',
            production: 'https://js-eu1.hs-scripts.com/139578958.js',
        };
        return (_b = urls[env]) !== null && _b !== void 0 ? _b : urls['local'];
    }, []);
    // methods
    const loadHubSpot = useCallback(() => {
        const scriptElement = document.createElement('script');
        scriptElement.src = hubspotTrackingURL !== null && hubspotTrackingURL !== void 0 ? hubspotTrackingURL : '';
        scriptElement.type = 'text/javascript';
        scriptElement.async = true;
        scriptElement.addEventListener('load', () => {
            window.hsConversationsSettings = Object.assign(Object.assign({}, window.hsConversationsSettings), { identificationEmail: kindeEmail, identificationToken: token, inlineEmbedSelector: '#hubspot-chat', loadImmediately: true });
        });
        scriptElement.addEventListener('error', () => {
            console.error('Error loading HubSpot script');
        });
        document.body.appendChild(scriptElement);
        return scriptElement;
    }, []);
    // effects
    useEffect(() => {
        var _a, _b;
        const scriptElement = loadHubSpot();
        (_b = (_a = window === null || window === void 0 ? void 0 : window.HubSpotConversations) === null || _a === void 0 ? void 0 : _a.widget) === null || _b === void 0 ? void 0 : _b.load();
        return () => {
            var _a, _b;
            (_b = (_a = window === null || window === void 0 ? void 0 : window.HubSpotConversations) === null || _a === void 0 ? void 0 : _a.widget) === null || _b === void 0 ? void 0 : _b.remove();
            scriptElement.remove();
        };
    }, []);
    const Global = createGlobalStyle ``;
    return (_jsxs(_Fragment, { children: [_jsx(Global, {}), _jsx(Wrapper, { id: 'hubspot-chat' })] }));
};
const Wrapper = styled.div `
  width: 100%;
  height: 100%;

  & #hubspot-conversations-inline-parent,
  & iframe {
    border: none;
    width: 100% !important;
    height: 100% !important;
    overflow: hidden;
  }
`;
HubSpotChat.displayName = 'HubSpotChat';
export default HubSpotChat;
