import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
// components
import { Container, Icon, ProgressBar } from "../../atoms";
import { toGenericOnboarding, } from "../../pages/OnboardingFlow/OnboardingFlow";
const animationDuration = 1000;
export const OnboardingLoading = toGenericOnboarding(({ config, currentIndex = 0, }) => {
    // state
    const [isVisible, setIsVisible] = useState(false);
    // memos
    const currentConfig = useMemo(() => config[0], [config]);
    // effects
    useEffect(() => {
        setIsVisible(false);
        const timer = setTimeout(() => {
            setIsVisible(true);
        }, animationDuration / 2);
        return () => clearInterval(timer);
    }, [currentIndex, animationDuration]);
    // guard clause
    if (!currentConfig)
        return _jsx(_Fragment, {});
    return (_jsx(StyledContainer, Object.assign({ size: 'sm', color: currentConfig.bgColor }, { children: _jsxs(Wrapper, { children: [_jsxs(ContentWrapper, Object.assign({ "$isVisible": isVisible }, { children: [(currentConfig === null || currentConfig === void 0 ? void 0 : currentConfig.icon) && (_jsx(ImageWrapper, Object.assign({ "$color": currentConfig.color, "$bgColor": currentConfig.bgColor }, { children: _jsx(Icon, { fontSize: 40, icon: currentConfig.icon }) }))), _jsx(Text, { children: currentConfig.text })] })), _jsx(ProgressBar, { label: ' ', limit: config.length - 1, progress: currentIndex, color: currentConfig.color })] }) })));
});
const ContentWrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 172px;
  gap: ${(props) => props.theme.spacing[32]};
`;
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding: 0 ${(props) => props.theme.spacing[24]};
`;
const StyledContainer = styled(Container) `
  background: ${(props) => { var _a; return (_a = props.theme.color.cards[props.color]) === null || _a === void 0 ? void 0 : _a.primary; }};
  transition-duration: ${animationDuration}ms;
  height: unset;
  flex: 1;
`;
const ImageWrapper = styled.div `
  width: 72px;
  height: 72px;
  min-width: 72px;
  min-height: 72px;
  max-width: 72px;
  max-height: 72px;
  border-radius: 999999px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => { var _a; return (_a = props.theme.color.cards[props.$color]) === null || _a === void 0 ? void 0 : _a.tertiary; }};
  background: ${(props) => { var _a; return (_a = props.theme.color.cards[props.$bgColor]) === null || _a === void 0 ? void 0 : _a.tertiary; }};
  padding: ${(props) => props.theme.spacing[12]};
`;
const Text = styled.div `
  font: ${(props) => props.theme.typography.body.md.regular};
  color: ${(props) => props.theme.color.neutral[90]};
  text-align: center;
`;
OnboardingLoading.displayName = 'OnboardingLoading';
export default toGenericOnboarding(OnboardingLoading);
