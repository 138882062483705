import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useMemo } from 'react';
import styled, { css } from 'styled-components';
// components
import { ActionPlanCard } from "../../molecules";
import { Icon } from "../../atoms/Icon/Icon";
// theme
import { theme } from "../../../theme";
export const ActionPlanList = ({ actionPlanCategory, actionPlanItems, }) => {
    // memos
    const sortedActionPlanItems = useMemo(() => {
        const actionPlanItemsCopy = actionPlanItems === null || actionPlanItems === void 0 ? void 0 : actionPlanItems.slice();
        return actionPlanItemsCopy === null || actionPlanItemsCopy === void 0 ? void 0 : actionPlanItemsCopy.sort((a, b) => a.order - b.order);
    }, [actionPlanItems]);
    // get the index of the current action to complete
    const currentActionIndex = useMemo(() => (sortedActionPlanItems === null || sortedActionPlanItems === void 0 ? void 0 : sortedActionPlanItems.findIndex((item) => item.isComplete === false)) ||
        0, [sortedActionPlanItems]);
    // determine the route based on ActionType
    const getRoute = (card) => {
        switch (card.actionType) {
            case 'direct':
                return `${card.route}`;
            default:
                return `/action-plan/${card.id}`;
        }
    };
    return (_jsxs(Wrapper, { children: [_jsx(Connector, {}), sortedActionPlanItems === null || sortedActionPlanItems === void 0 ? void 0 : sortedActionPlanItems.map((card, currentIndex) => (_jsxs(ActionRow, { children: [_jsx(IconWrapper, { children: _jsx(StyledIcon, { icon: currentIndex === currentActionIndex
                                ? 'long-arrow-right'
                                : 'check', fontSize: 16, fill: currentIndex === currentActionIndex
                                ? theme.color.warning[40]
                                : card.isComplete
                                    ? theme.color.success[40]
                                    : theme.color.neutral[30], "$color": currentIndex !== currentActionIndex && !card.isComplete
                                ? theme.color.neutral[10]
                                : undefined }) }), _jsx(Containier, { children: _createElement(ActionPlanCard, Object.assign({ color: actionPlanCategory === null || actionPlanCategory === void 0 ? void 0 : actionPlanCategory.color, icon: actionPlanCategory === null || actionPlanCategory === void 0 ? void 0 : actionPlanCategory.icon }, card, { to: getRoute(card), key: card.id, isModify: card.isModify })) })] })))] }));
};
const StyledIcon = styled(Icon) `
  ${(props) => props.$color &&
    css `
      color: ${props.$color};
    `}
`;
const IconWrapper = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Connector = styled.div `
  height: 78%;
  width: 3rem;
  position: absolute;
  top: 11%;
  left: 16px;
  border-left: 2px dashed ${theme.color.neutral[50]};
  z-index: -1;
`;
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  position: relative;
  gap: ${theme.spacing[8]};
`;
const Containier = styled.div `
  width: 100%;
`;
const ActionRow = styled.div `
  gap: ${(props) => props.theme.spacing[12]};
  display: flex;
`;
ActionPlanList.displayName = 'ActionPlanList';
export default ActionPlanList;
