import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import styled from 'styled-components';
// components
import { Container, Divider, ProgressBar, Title } from "../../atoms";
import { ActionPlanPathway } from "../../organisms";
/**
 * ActionPlan shows the list of action plan items.
 */
export const ActionPlan = ({ actionPlanItems, categories, onDismiss, }) => {
    return (_jsxs(Wrapper, { children: [_jsx(Container, { children: _jsxs(Header, { children: [_jsx(Title, Object.assign({ size: 'sm', isNoMargin: true }, { children: "My Actions" })), _jsx(ProgressBar, { label: 'Progress', limit: actionPlanItems.length, progress: actionPlanItems.filter((item) => item.isComplete).length, isAnimate: false })] }) }), _jsx(Divider, { isOr: false }), _jsx(Container, Object.assign({ size: 'md' }, { children: _jsx(Body, { children: _jsx(ActionPlanPathway, { actionPlanCategories: categories }) }) }))] }));
};
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[24]};
`;
const Header = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[8]};
  padding-top: ${(props) => props.theme.spacing[24]};
`;
const Body = styled.div `
  padding-top: ${(props) => props.theme.spacing[16]};
`;
ActionPlan.displayName = 'ActionPlan';
export default ActionPlan;
