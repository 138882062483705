import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useMemo } from 'react';
import styled, { css } from 'styled-components';
// radix
import * as RadixAvatar from '@radix-ui/react-avatar';
// components
import { Icon } from "../Icon";
const sizeMap = {
    xxs: 24,
    xs: 40,
    sm: 48,
    md: 64,
    lg: 112,
    xl: 192,
};
/**
 * Avatar shows an image of the user.
 */
export const Avatar = ({ className, size = 'sm', src, fallback, icon, onClick, }) => {
    // memos
    const iconSize = useMemo(() => {
        switch (size) {
            case 'xxs':
                return 12;
            case 'xs':
                return 16;
            case 'sm':
                return 20;
            case 'md':
                return 24;
            case 'lg':
                return 32;
            case 'xl':
                return 48;
            default:
                return 24;
        }
    }, [size]);
    return (_jsx(AvatarRoot, Object.assign({ className: className, size: size, onClick: onClick }, { children: icon ? (_jsx(Icon, { icon: icon, fontSize: iconSize })) : (_jsxs(_Fragment, { children: [_jsx(AvatarImage, { src: src, alt: 'avatar' }), _jsx(AvatarFallback, Object.assign({ size: size, delayMs: 0 }, { children: fallback }))] })) })));
};
const AvatarRoot = styled(RadixAvatar.Root) `
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  overflow: hidden;
  user-select: none;
  border-radius: 9999px;
  background: ${(props) => props.theme.color.surfaces.lightPlum};
  color: ${(props) => props.theme.color.accent.moccasin};
  ${(props) => {
    let size = sizeMap[props.size || 'md'];
    return css `
      width: ${size}px;
      height: ${size}px;
      min-width: ${size}px;
      min-height: ${size}px;
      max-width: ${size}px;
      max-height: ${size}px;
    `;
}}
`;
const AvatarImage = styled(RadixAvatar.Image) `
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
`;
const AvatarFallback = styled(RadixAvatar.Fallback) `
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) => {
    switch (props.size) {
        case 'xxs':
            return css `
          font: ${props.theme.typography.body.xs.regular};
        `;
        case 'xs':
            return css `
          font: ${props.theme.typography.body.md.regular};
        `;
        case 'sm':
            return css `
          font: ${props.theme.typography.body.lg.regular};
        `;
        case 'md':
            return css `
          font: ${props.theme.typography.body.xl.regular};
        `;
        case 'lg':
            return css `
          font: ${props.theme.typography.body.xxl.regular};
        `;
        case 'xl':
            return css `
          font: ${props.theme.typography.body.xxxl.regular};
        `;
    }
}}
`;
Avatar.displayName = 'Avatar';
export default Avatar;
